export const Logo = ({width}) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                width={width} viewBox="0.773 0 96.467 85.521" enableBackground="new 0.773 0 96.467 85.521">
            <g>
                <path fill="none" d="M35.094,75.436c-0.745-0.191-1.483-0.4-2.208-0.641C33.612,75.034,34.349,75.244,35.094,75.436z"/>
                <path fill="none" d="M49.818,75.944c-0.295,0.055-0.592,0.102-0.89,0.147C49.226,76.046,49.523,75.999,49.818,75.944z"/>
                <path fill="none" d="M51.326,75.626c-0.285,0.067-0.573,0.127-0.86,0.187C50.753,75.753,51.041,75.693,51.326,75.626z"/>
                <path fill="none" d="M54.271,74.792c-0.272,0.09-0.547,0.176-0.822,0.26C53.724,74.968,53.998,74.882,54.271,74.792z"/>
                <path fill="none" d="M52.81,75.242c-0.277,0.078-0.558,0.15-0.838,0.222C52.252,75.393,52.532,75.32,52.81,75.242z"/>
                <path fill="none" d="M48.288,76.194c-0.311,0.043-0.625,0.076-0.938,0.111C47.663,76.271,47.976,76.237,48.288,76.194z"/>
                <path fill="none" d="M37.871,76.035c-0.768-0.131-1.524-0.295-2.274-0.476C36.347,75.739,37.103,75.904,37.871,76.035z"/>
                <path fill="none" d="M45.17,76.483c-0.526,0.023-1.054,0.038-1.585,0.038C44.116,76.521,44.644,76.508,45.17,76.483z"/>
                <path fill="none" d="M46.738,76.374c-0.349,0.032-0.701,0.054-1.053,0.075C46.037,76.427,46.389,76.406,46.738,76.374z"/>
                <path fill="none" d="M55.703,74.277c-0.269,0.104-0.538,0.202-0.81,0.298C55.165,74.479,55.435,74.381,55.703,74.277z"/>
                <path fill="none" d="M64.919,68.92c-0.27,0.221-0.539,0.439-0.814,0.651C64.38,69.359,64.651,69.14,64.919,68.92z"/>
                <path fill="none" d="M57.108,73.699c-0.264,0.116-0.53,0.229-0.799,0.338C56.578,73.927,56.845,73.815,57.108,73.699z"/>
                <path fill="none" d="M63.683,69.885c-0.261,0.192-0.521,0.386-0.785,0.57C63.163,70.27,63.422,70.077,63.683,69.885z"/>
                <path fill="none" d="M66.209,67.812c-0.308,0.278-0.619,0.555-0.937,0.821C65.59,68.366,65.901,68.091,66.209,67.812z"/>
                <path fill="none" d="M58.481,73.062c-0.26,0.128-0.521,0.254-0.787,0.376C57.959,73.315,58.222,73.189,58.481,73.062z"/>
                <path fill="none" d="M59.827,72.36c-0.258,0.142-0.516,0.281-0.776,0.416C59.312,72.642,59.569,72.502,59.827,72.36z"/>
                <path fill="none" d="M62.425,70.774c-0.257,0.173-0.514,0.346-0.773,0.511C61.913,71.119,62.168,70.947,62.425,70.774z"/>
                <path fill="none" d="M61.144,71.598c-0.258,0.155-0.516,0.313-0.775,0.462C60.628,71.91,60.886,71.754,61.144,71.598z"/>
                <path fill="none" d="M32.751,74.747c-0.709-0.237-1.407-0.501-2.095-0.784C31.344,74.245,32.042,74.51,32.751,74.747z"/>
                <path fill="none" d="M28.099,72.789c-0.72-0.37-1.426-0.766-2.116-1.186C26.673,72.023,27.378,72.42,28.099,72.789z"/>
                <path fill="none" d="M30.33,73.829c-0.731-0.31-1.452-0.641-2.156-1.001C28.879,73.188,29.599,73.521,30.33,73.829z"/>
                <path fill="none" d="M63.06,65.186c0.078,0.144,0.149,0.287,0.219,0.432c0.175,0.095,0.337,0.197,0.505,0.295
                    C63.69,65.791,63.483,65.555,63.06,65.186z"/>
                <path fill="#5995B2" d="M76.813,48.427c-1.303,7.65-5.08,14.314-10.43,19.23c0.001,0,0.001,0,0.001,0
                    c-0.058,0.053-0.118,0.102-0.176,0.154c-0.308,0.278-0.619,0.555-0.937,0.821c-0.116,0.099-0.236,0.189-0.354,0.286
                    c-0.269,0.221-0.539,0.439-0.813,0.651c-0.141,0.105-0.283,0.209-0.424,0.313c-0.26,0.192-0.52,0.386-0.783,0.57
                    c-0.156,0.108-0.315,0.213-0.474,0.319c-0.257,0.173-0.513,0.345-0.774,0.511c-0.168,0.106-0.338,0.209-0.508,0.313
                    c-0.257,0.155-0.515,0.313-0.774,0.462c-0.179,0.103-0.359,0.2-0.541,0.301c-0.257,0.142-0.517,0.281-0.776,0.416
                    c-0.188,0.098-0.378,0.19-0.568,0.285c-0.26,0.128-0.521,0.254-0.787,0.376c-0.193,0.089-0.389,0.176-0.586,0.262
                    c-0.264,0.116-0.53,0.229-0.799,0.338c-0.201,0.082-0.403,0.162-0.606,0.24c-0.269,0.104-0.538,0.202-0.81,0.298
                    c-0.207,0.074-0.414,0.146-0.623,0.217c-0.272,0.09-0.547,0.176-0.822,0.26c-0.213,0.064-0.426,0.131-0.639,0.19
                    c-0.277,0.078-0.558,0.15-0.838,0.222c-0.216,0.057-0.43,0.111-0.646,0.162c-0.285,0.067-0.573,0.127-0.86,0.187
                    c-0.216,0.045-0.431,0.092-0.647,0.133c-0.295,0.055-0.592,0.102-0.89,0.147c-0.214,0.034-0.426,0.071-0.641,0.103
                    c-0.312,0.043-0.625,0.076-0.938,0.111c-0.204,0.021-0.406,0.05-0.611,0.067c-0.349,0.032-0.701,0.054-1.053,0.075
                    c-0.172,0.011-0.342,0.026-0.515,0.034c-0.526,0.023-1.054,0.038-1.585,0.038c-1.9,0-3.823-0.164-5.714-0.486
                    c-0.768-0.131-1.524-0.296-2.274-0.476c-0.168-0.041-0.335-0.081-0.502-0.124c-0.745-0.191-1.482-0.401-2.208-0.641
                    c-0.046-0.016-0.09-0.033-0.136-0.049c-0.709-0.237-1.407-0.502-2.095-0.784c-0.108-0.045-0.218-0.088-0.326-0.134
                    c-0.731-0.309-1.451-0.642-2.156-1.001c-0.025-0.013-0.05-0.026-0.075-0.039c-0.72-0.369-1.425-0.766-2.116-1.186
                    c-0.023-0.016-0.047-0.028-0.071-0.042c-3.85-2.355-7.222-5.484-9.911-9.276c-5.215-7.356-7.253-16.303-5.74-25.19
                    c0.454-2.667,1.214-5.211,2.232-7.606c-0.011-0.245-0.02-0.484-0.027-0.716c-0.026-0.743-0.05-1.445-0.152-2.142
                    c-0.253-1.726-1.884-3.87-2.419-4.573C9.091,21,8.351,20.137,7.678,19.432C4.585,24.203,2.399,29.658,1.39,35.584
                    C-0.527,46.842,2.055,58.173,8.66,67.49c6.605,9.316,16.443,15.502,27.701,17.418c2.388,0.406,4.819,0.613,7.224,0.613
                    c20.885,0,38.59-14.965,42.102-35.584c0.369-2.175,0.567-4.354,0.605-6.519c-2.751,0.109-5.831,0.211-9.011,0.278
                    C77.233,45.271,77.083,46.85,76.813,48.427z"/>
                <path fill="#5995B2" d="M33.263,8.257l1.204,0.159c1.587,0.205,3.309,0.445,5.013,0.828C40.798,9.088,42.136,9,43.493,9
                    c1.9,0,3.821,0.163,5.711,0.485c8.889,1.513,16.654,6.396,21.87,13.752c1.06,1.494,1.979,3.057,2.774,4.669
                    c3.711,0.683,7.332,1.545,10.607,2.429c-1.323-4.342-3.339-8.492-6.041-12.303C71.81,8.716,61.972,2.529,50.714,0.612
                    C48.327,0.206,45.897,0,43.493,0C34.53,0,26.156,2.763,19.235,7.539c0.865-0.025,1.745-0.045,2.658-0.045
                    C25.731,7.494,29.556,7.75,33.263,8.257z"/>
                <path fill="#F2F4F2" d="M59.151,29.464c-1.602,1.192-3.991,0.547-3.991,0.547c2.065-2.335,0.899-4.903,0.899-4.903
                    c3.342-0.563,5.984,0.656,7.668,1.838c-0.168-0.202-0.328-0.41-0.474-0.624c-0.528-0.777-1.341-1.553-2.438-2.306
                    c0.252,0.245,0.504,0.491,0.742,0.734c0,0-3.672-3.19-11.353-2.473c-7.68,0.718-3.953,2.563-10.354,1.583
                    c-6.401-0.982-18.625-1.873-19.442,7.849c-0.609,7.244,0.902,12.293,1.712,14.398c0.534,1.213,1.089,2.391,1.631,3.484
                    c5.17,1.294,3.47,7.114,6.521,9.341c3.14,2.29,12.706,0.271,28.511,4.841c1.662,0.479,3.146,1.112,4.494,1.844
                    c-0.069-0.145-0.141-0.288-0.221-0.432c-1.248-1.088-4.387-3.327-12.063-7.149c-11.707-5.828-17.503-11.148-8.45-17.229
                    c9.052-6.08,20.53-7.455,26.694-7.291s-1.953-3.068-1.953-3.068c0.458,0.053,0.918,0.111,1.378,0.173
                    c-0.938-0.48-1.993-1.097-2.978-1.842C65.019,28.716,60.629,28.365,59.151,29.464z M51.162,26.946
                    c0.203-1.109,1.078-1.876,1.953-1.715c0.877,0.16,1.422,1.188,1.219,2.297c-0.203,1.109-1.078,1.877-1.955,1.717
                    C51.503,29.083,50.958,28.055,51.162,26.946z M34.412,31.12c1.791-1.104-0.747-2.902-5.123-0.838
                    c-4.374,2.064-7.993,1.965-4.215-1.594c3.778-3.56,8.736-3.775,13.21-2.748c4.472,1.024,4.554,0.58,6.867-0.021
                    c2.313-0.602,2.844-0.145,2.844-0.145s-0.627,3.981,3.021,4.755c0,0-1.738,1.935-6.322,2.066c-4.584,0.133-9.021-0.529-11.21,1.63
                    C33.483,34.228,32.621,32.226,34.412,31.12z"/>
                <path fill="#574847" d="M69.24,33.515c-6.164-0.164-17.644,1.211-26.694,7.291c-9.053,6.081-3.256,11.401,8.45,17.229
                    c7.677,3.822,10.813,6.063,12.063,7.149c0.424,0.369,0.631,0.604,0.724,0.727c0.009,0.005,0.018,0.01,0.026,0.015
                    c-0.175-0.34-0.972-1.859-2.265-3.811c-1.847-2.784-4.692-6.422-8.159-8.674c-6.226-4.047-1.408-13.42,12.488-13.19
                    C79.767,40.479,97,39.387,97,39.387l0.24-1.312c0,0-14.577-5.579-28.575-7.457c-0.46-0.062-0.92-0.12-1.378-0.173
                    C67.287,30.446,75.403,33.679,69.24,33.515z"/>
                <path fill="#F05423" d="M22.122,46.107c-0.81-2.105-2.321-7.154-1.712-14.398c0.817-9.722,13.041-8.831,19.442-7.849
                    c6.401,0.98,2.674-0.865,10.354-1.583c7.682-0.719,11.354,2.473,11.354,2.473c-0.24-0.243-0.49-0.489-0.742-0.734
                    c-2.691-2.627-6.166-5.426-11.332-7.771c-4.251-1.932-9.642-3.557-16.696-4.52C16.361,9.481,3.5,13.12,3.5,13.12
                    c3.507-0.131,13.282,8.825,12.419,15.635c-0.317,2.504,0.502,5.66,1.665,8.651c1.998,5.145,5.011,9.801,5.011,9.801
                    S22.399,46.827,22.122,46.107z"/>
                
                    <ellipse transform="matrix(0.9836 0.1804 -0.1804 0.9836 5.7808 -9.0704)" fill="#574847" cx="52.749" cy="27.241" rx="1.613" ry="2.039"/>
                <path fill="#574847" d="M44.694,32.598c4.584-0.133,6.321-2.066,6.321-2.066c-3.646-0.772-3.021-4.755-3.021-4.755
                    s-0.531-0.457-2.844,0.145c-2.313,0.601-2.395,1.045-6.867,0.021c-4.474-1.027-9.432-0.812-13.21,2.748
                    c-3.778,3.559-0.159,3.658,4.215,1.594c4.376-2.064,6.914-0.268,5.123,0.838c-1.791,1.106-0.929,3.107-0.929,3.107
                    C35.673,32.068,40.11,32.73,44.694,32.598z"/>
                <path fill="#574847" d="M56.06,25.106c0,0,1.166,2.568-0.899,4.903c0,0,2.391,0.646,3.991-0.547
                    c1.479-1.099,5.867-0.748,6.537-0.687c0.056,0.005,0.095,0.009,0.095,0.009s-0.312-0.381-0.878-0.896
                    c-0.315-0.287-0.705-0.611-1.178-0.944C62.044,25.763,59.401,24.545,56.06,25.106z"/>
                <path fill="#574847" d="M63.783,65.912c-0.168-0.098-0.33-0.2-0.505-0.295c-1.348-0.729-2.83-1.363-4.493-1.844
                    c-15.806-4.569-25.372-2.551-28.511-4.841c-3.051-2.227-1.35-8.047-6.521-9.341c-0.149-0.036-0.283-0.085-0.444-0.113
                    c0,0,7.127,10.011-0.77,15.682c0,0-1.417,0.518-2.607,1.729c1.82,1.786,3.825,3.352,5.98,4.673
                    c0.023,0.014,0.047,0.026,0.071,0.042c0.69,0.42,1.396,0.814,2.116,1.186c0.025,0.013,0.05,0.026,0.075,0.039
                    c0.705,0.36,1.425,0.691,2.156,1.001c0.108,0.046,0.217,0.089,0.326,0.134c0.688,0.283,1.386,0.547,2.095,0.784
                    c0.045,0.017,0.09,0.033,0.136,0.049c0.725,0.239,1.463,0.448,2.208,0.641c0.167,0.043,0.334,0.083,0.502,0.124
                    c0.75,0.181,1.506,0.345,2.274,0.476c1.891,0.322,3.813,0.486,5.714,0.486c0.531,0,1.06-0.015,1.585-0.038
                    c0.172-0.008,0.343-0.023,0.515-0.034c0.352-0.021,0.704-0.043,1.053-0.075c0.205-0.019,0.407-0.046,0.611-0.067
                    c0.313-0.035,0.627-0.068,0.938-0.111c0.214-0.03,0.427-0.067,0.641-0.103c0.297-0.047,0.595-0.094,0.89-0.147
                    c0.217-0.041,0.432-0.088,0.647-0.133c0.288-0.06,0.575-0.118,0.86-0.187c0.217-0.051,0.431-0.105,0.646-0.162
                    c0.279-0.07,0.559-0.144,0.836-0.222c0.215-0.061,0.426-0.126,0.639-0.189c0.275-0.085,0.551-0.171,0.822-0.261
                    c0.209-0.069,0.416-0.143,0.623-0.217c0.271-0.096,0.541-0.194,0.811-0.298c0.203-0.078,0.404-0.158,0.607-0.24
                    c0.268-0.108,0.533-0.222,0.799-0.338c0.195-0.086,0.392-0.173,0.586-0.262c0.265-0.122,0.525-0.248,0.787-0.376
                    c0.189-0.095,0.379-0.188,0.567-0.285c0.261-0.135,0.521-0.274,0.776-0.416c0.182-0.101,0.361-0.198,0.541-0.301
                    c0.261-0.149,0.519-0.307,0.775-0.462c0.17-0.104,0.34-0.206,0.508-0.313c0.261-0.165,0.518-0.338,0.773-0.511
                    c0.157-0.106,0.316-0.211,0.473-0.319c0.266-0.186,0.524-0.378,0.785-0.57c0.141-0.104,0.282-0.207,0.422-0.313
                    c0.275-0.212,0.547-0.432,0.814-0.651c0.117-0.097,0.236-0.188,0.354-0.286c0.317-0.268,0.629-0.543,0.938-0.821
                    c0.059-0.054,0.118-0.103,0.176-0.154c0,0,0,0-0.001,0c-0.78-0.625-1.653-1.194-2.575-1.729
                    C63.8,65.922,63.792,65.917,63.783,65.912z"/>
            </g>
        </svg>
    )
}
