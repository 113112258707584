export const HomeImage = () => {
    return (
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    viewBox="0 0 568.941 364.768" enableBackground="new 0 0 568.941 364.768"
                >
                <g>
                    <g>
                        <g opacity="0.6">
                            <path fill="#F15625" d="M348.503,154.32c0,0-2.932,24.077-38.086,27.876c0,0-23.467,0.496-41.908-25.124l-24.933,7.622
                                c0,0-13.053,4.581-17.434,9.822c-4.382,5.241-69.57,98.048-69.57,98.048s-13.771,19.983-0.782,33.192l8.213,5.947l58.017,2.305
                                l11.543-20.4l-58.032-21.044l3.116-7.621l28.783,12.01l35.107-49.521l4.214,4.009l-10.188,13.81l1.559,20.828l-1.559,20.164
                                l1.039,4.12l21.892,1.502l15.174,5.613L286.69,309.7l0.604-76.333l6.658-12.866l17.539-3.342l99.732-0.931h16.23l-29.738-45.689
                                c0,0-17.141-7.143-21.945-8.96C370.968,159.76,348.503,154.32,348.503,154.32z"/>
                        </g>
                        <g opacity="0.3">
                            <path fill="#5A95B2" d="M456.269,223.498c-1.625-0.533-3.357-0.824-5.16-0.824H313.154c-9.168,0-16.599,7.434-16.599,16.602
                                v102.974h162.222V232.265C458.777,229.044,457.857,226.042,456.269,223.498z M387.291,298.417
                                c-6.459,0-11.697-5.235-11.697-11.695c0-6.461,5.238-11.698,11.697-11.698c6.461,0,11.697,5.237,11.697,11.698
                                C398.988,293.181,393.75,298.417,387.291,298.417z"/>
                        </g>
                        <g opacity="0.6">
                            <path fill="#5A95B2" d="M341.884,37.084l-4.848,14.852l12.916,35.784c0,0,10.246-27.16,7.223-35.784
                                C351.742,36.431,341.884,37.084,341.884,37.084z"/>
                        </g>
                        <g opacity="0.6">
                            <path fill="#5A95B2" d="M262.689,88.513c0,0-13.45-21.442-4.183-45.953c2.879-7.611,24.652-24.047,45.171-24.098
                                c22.635-0.054,29.314,11.838,29.314,11.838s-3.135,21.492-13.459,28.502c-16.847,11.44-31.869,6.322-31.869,6.322l-10.973,10.057
                                l2.465-28.561c0,0-6.734,17.064-4.166,33.289c0.107,0.673-4.296,3.442-4.296,3.442l-2.45-29.464c0,0-5.029,12.614,2.921,28.648
                                c0.639,1.289-2.452,7.838-2.452,7.838L262.689,88.513z"/>
                        </g>
                        <g>
                            <path fill="#584948" d="M354.324,88.514l-3.271-1.589c0.123-0.252,12.156-25.411,4.824-41.249
                                c-2.688-5.809-7.762-9.71-15.082-11.596l0.906-3.521c8.432,2.172,14.311,6.746,17.479,13.596
                                C367.23,61.564,354.853,87.423,354.324,88.514z"/>
                        </g>
                        <g>
                            <path fill="#584948" d="M345.38,112.125c-0.086,0-0.133-0.003-0.139-0.004l0.236-3.627c0.201-0.006,5.727,0.104,7.168-9.386
                                c0.287-1.896-0.193-4.033-1.168-5.199c-0.508-0.606-1.109-0.911-1.844-0.931l0.102-3.636c1.783,0.05,3.352,0.821,4.531,2.233
                                c1.645,1.968,2.418,5.139,1.975,8.078C354.416,111.679,346.521,112.125,345.38,112.125z"/>
                        </g>
                        <g>
                            <path fill="#584948" d="M271.123,112.125c-0.099-0.002-9.783-0.384-9.812-13.539c-0.012-5.135,3.652-9.109,8.521-9.244l0.1,3.636
                                c-2.896,0.08-4.992,2.434-4.985,5.6c0.021,9.653,6.007,9.906,6.262,9.912L271.123,112.125z"/>
                        </g>
                        <g>
                            <path fill="#584948" d="M307.541,152.557c-6.574,0-14.441-3.871-21.275-10.521c-7.474-7.27-12.887-17.021-14.852-26.754
                                c-1.872-9.273-2.385-20.47-2.608-28.085l3.634-0.106c0.22,7.482,0.722,18.471,2.538,27.474
                                c1.824,9.032,6.861,18.095,13.823,24.868c6.464,6.285,13.75,9.813,19.523,9.464l0.227,3.63
                                C308.218,152.546,307.88,152.557,307.541,152.557z"/>
                        </g>
                        <g>
                            <path fill="#584948" d="M290.417,73.852l-0.308-3.623c35.19-2.981,42.987-19.283,45.976-34.835l3.572,0.686
                                C336.603,51.982,328.248,70.647,290.417,73.852z"/>
                        </g>
                        <g>
                            <path fill="#584948" d="M275.693,88.094l-2.946-1.109c-0.524-0.198-12.808-5.035-12.374-22.896l3.635,0.088
                                c-0.241,9.886,3.964,15.078,7.001,17.527c-2.292-16.807-0.64-29,5.349-40.229l3.208,1.711
                                c-6.032,11.312-7.344,23.813-4.385,41.802L275.693,88.094z"/>
                        </g>
                        <g>
                            <path fill="#584948" d="M274.754,83.967l-2.733-2.397c1.785-2.034,3.69-4.005,5.533-5.913
                                c8.013-8.293,15.582-16.127,15.582-30.211h3.637c0,15.553-8.441,24.289-16.604,32.738
                                C278.356,80.059,276.481,81.999,274.754,83.967z"/>
                        </g>
                        <g>
                            <path fill="#584948" d="M308.31,12.783c2.588,0.058,5.102,0.243,7.486,0.562c13.365,1.788,21.938,7.382,24.783,16.178
                                l-3.457,1.12c-5.203-16.068-34.885-16.675-53.41-10.951c-11.508,3.554-20.917,11.732-25.167,21.877
                                c-5.377,12.833-3.292,28.634,6.197,46.968l-3.23,1.671c-10.006-19.334-12.133-36.171-6.321-50.044
                                c6.72-16.037,21.434-22.089,27.447-23.947C290.457,13.802,299.761,12.592,308.31,12.783"/>
                        </g>
                        <g>
                            <rect x="289.056" y="92.231" fill="#584948" width="3.865" height="3.635"/>
                        </g>
                        <g>
                            <rect x="325.482" y="92.396" fill="#584948" width="3.645" height="3.635"/>
                        </g>
                        <g>
                            <rect x="306.523" y="138.155" fill="#584948" width="3.645" height="3.637"/>
                        </g>
                        <g>
                            <path fill="#584948" d="M308.214,125.95c-1.43,0-2.904-0.169-4.407-0.51c-2.384-0.54-4.515-1.433-6.336-2.651l2.021-3.021
                                c1.45,0.969,3.174,1.686,5.118,2.126c1.95,0.441,3.813,0.538,5.544,0.283l0.527,3.598
                                C309.876,125.891,309.054,125.95,308.214,125.95z"/>
                        </g>
                        <g>
                            <path fill="#584948" d="M291.847,94.575c-3.204,0-6.523-0.644-9.405-1.916l1.467-3.326c4.399,1.939,10.192,2.142,14.092,0.492
                                l1.416,3.348C297.205,94.111,294.567,94.575,291.847,94.575z"/>
                        </g>
                        <g>
                            <path fill="#584948" d="M326.876,94.575c-2.916,0-5.75-0.526-8.092-1.581l1.494-3.314c3.859,1.739,9.656,1.671,14.1-0.169
                                l1.391,3.36C333.027,94.006,329.908,94.575,326.876,94.575z"/>
                        </g>
                        <g>
                            <path fill="#584948" d="M318.125,113.397h-1.93c-1.473,0-3.229-0.017-5.031-0.032c-1.887-0.017-3.828-0.034-5.537-0.034v-3.635
                                c1.721,0,3.672,0.017,5.57,0.034c1.049,0.009,2.082,0.018,3.053,0.024c-0.043-0.583-0.092-1.268-0.143-2.027
                                c-0.361-5.131-0.967-13.72-1.252-18.928l-0.016-0.276l0.068-0.271c0.877-3.454,3.082-6.263,6.373-8.128
                                c4.105-2.324,9.563-2.896,14.232-1.49l-1.047,3.481c-3.699-1.112-8.172-0.651-11.396,1.174c-1.631,0.924-3.727,2.64-4.568,5.601
                                c0.291,5.205,0.877,13.554,1.23,18.58c0.143,2.017,0.244,3.489,0.275,3.998L318.125,113.397z"/>
                        </g>
                        <g>
                            <path fill="#584948" d="M300.085,84.078c-4.455-3.255-12.5-3.839-19.127-1.386l-1.262-3.411
                                c7.81-2.889,17.077-2.126,22.533,1.861L300.085,84.078z"/>
                        </g>
                        <g>
                            
                                <rect x="283.382" y="139.019" transform="matrix(-0.9938 -0.1109 0.1109 -0.9938 552.5245 321.3403)" fill="#584948" width="3.636" height="12.565"/>
                        </g>
                        <g>
                            
                                <rect x="327.391" y="142.11" transform="matrix(-0.1111 -0.9938 0.9938 -0.1111 227.7233 491.5323)" fill="#584948" width="12.564" height="3.638"/>
                        </g>
                        <g>
                            <path fill="#584948" d="M308.58,152.533c-0.123,0-0.244-0.002-0.367-0.005l0.086-3.635c0.092,0.003,0.184,0.003,0.275,0.003
                                c10.766,0,29.346-14.234,33.4-34.332c1.861-9.214,2.32-17.394,2.539-24.858l0.021-0.231c2.463-15.533-0.453-18.574-4.486-22.782
                                c-3.039-3.171-6.822-7.116-8.836-16.801l3.561-0.741c1.801,8.664,4.9,11.897,7.898,15.024c4.389,4.579,8.184,8.535,5.473,25.753
                                c-0.225,7.597-0.699,15.925-2.604,25.355c-1.963,9.722-7.377,19.161-15.248,26.579
                                C323.175,148.566,315.089,152.533,308.58,152.533z"/>
                        </g>
                        <g>
                            <path fill="#584948" d="M427.519,217.225c-13.773-21.018-22.014-33.771-26.441-40.626c-1.928-2.988-3.096-4.792-3.637-5.606
                                c-2.061-3.088-13.246-9.459-19.93-12.813l-34.482-9.822l0.996-3.497l34.813,9.916l0.15,0.076
                                c1.852,0.927,18.188,9.188,21.479,14.122c0.557,0.837,1.729,2.648,3.666,5.65c4.424,6.852,12.658,19.601,26.428,40.606
                                L427.519,217.225z"/>
                        </g>
                        <g>
                            <path fill="#584948" d="M309.757,176.338c-1.24,0-2.475-0.069-3.707-0.21c-16.291-1.843-25.934-15.171-26.335-15.737l2.963-2.108
                                c0.089,0.125,9.153,12.597,23.817,14.237c10.113,1.129,20.596-3.062,31.146-12.461l2.418,2.714
                                C329.943,171.785,319.765,176.338,309.757,176.338z"/>
                        </g>
                        <g>
                            <g>
                                <path fill="#584948" d="M148.291,301.56c-5.916-4.968-4.857-16.908-2.152-25.623c1.356-4.372,2.454-5.908,3.267-6.47
                                    c4.875-6.963,67.259-96.045,70.224-100.492c3.289-4.935,19.625-13.195,21.477-14.122l0.152-0.076l0.163-0.046l34.648-9.87
                                    l0.997,3.497l-34.485,9.822c-6.681,3.354-17.868,9.725-19.926,12.813c-3.093,4.64-67.813,97.052-70.566,100.983l-0.48,0.686
                                    l-0.262,0.025c-1.273,1.959-4.145,10.791-3.694,18.255c0.225,3.729,1.227,6.364,2.978,7.834L148.291,301.56z"/>
                            </g>
                            <g>
                                <polygon fill="#584948" points="224.262,316.523 163.375,313.498 152.289,305.468 154.421,302.524 164.633,309.917 
                                    222.059,312.773 234.035,288.556 176.888,267.789 178.129,264.371 239.094,286.526 				"/>
                            </g>
                        </g>
                        <g>
                            
                                <rect x="224.321" y="222.648" transform="matrix(-0.8195 -0.573 0.573 -0.8195 268.6269 583.1495)" fill="#584948" width="3.637" height="53.255"/>
                        </g>
                        <g>
                            <polygon fill="#584948" points="233.257,284.623 229.626,284.419 230.65,266.104 228.728,243.12 232.352,242.816 
                                234.296,266.053 			"/>
                        </g>
                        <g>
                            <rect x="126.554" y="318.611" fill="#584948" width="65.451" height="3.635"/>
                        </g>
                        <g>
                            <g>
                                <path fill="#584948" d="M381.957,292.66c-4.613,0-8.367-3.755-8.367-8.37c0-4.616,3.754-8.371,8.367-8.371
                                    c4.615,0,8.373,3.755,8.373,8.371C390.33,288.905,386.572,292.66,381.957,292.66z M381.957,279.556
                                    c-2.607,0-4.73,2.122-4.73,4.732s2.123,4.732,4.73,4.732c2.611,0,4.736-2.122,4.736-4.732S384.568,279.556,381.957,279.556z"/>
                            </g>
                            <g>
                                <path fill="#584948" d="M381.957,297.83c-7.465,0-13.541-6.075-13.541-13.54c0-7.468,6.076-13.542,13.541-13.542
                                    c7.469,0,13.543,6.074,13.543,13.542C395.5,291.754,389.423,297.83,381.957,297.83z M381.957,274.384
                                    c-5.461,0-9.904,4.442-9.904,9.905c0,5.461,4.443,9.902,9.904,9.902s9.906-4.441,9.906-9.902
                                    C391.863,278.827,387.417,274.384,381.957,274.384z"/>
                            </g>
                            <g>
                                <path fill="#584948" d="M460.595,344.916H285.419v-110.12c0-11.238,9.144-20.385,20.385-20.385h134.404
                                    c11.242,0,20.387,9.146,20.387,20.385V344.916z M289.056,341.278h167.902V234.795c0-9.233-7.514-16.748-16.75-16.748H305.804
                                    c-9.234,0-16.748,7.515-16.748,16.748V341.278z"/>
                            </g>
                            <g>
                                <polygon fill="#584948" points="289.056,344.916 279.014,344.916 279.014,341.278 285.419,341.278 285.419,309.275 
                                    289.056,309.275 				"/>
                            </g>
                            <g>
                                <path fill="#584948" d="M278.514,353.567l-57.171-34.307v-4.613h3.637v2.556l54.535,32.724l171.352-0.651
                                    c3.352-0.036,6.094-2.811,6.094-6.178h3.637c0,5.351-4.354,9.753-9.703,9.814L278.514,353.567z"/>
                            </g>
                            <g>
                                <polygon fill="#584948" points="280.833,351.747 277.195,351.747 277.195,344.128 229.607,315.705 231.472,312.583 
                                    280.833,342.065 				"/>
                            </g>
                        </g>
                        <g>
                            <g>
                                <path fill="#584948" d="M275.295,322.304c-2.479,0-6.366-1.224-11.613-3.662c-4.417,0.766-12.147,1.24-16.943,1.196l0.027-3.635
                                    c4.741,0.033,12.802-0.465,16.814-1.24l0.584-0.113l0.538,0.254c4.577,2.159,9.537,3.811,10.829,3.601
                                    c0.313-0.305,0.752-1.006,0.959-1.436l1.017-2.109l1.792,1.509c1.293,1.087,1.976,1.176,2.114,1.118
                                    c0.216-0.086,0.466-0.599,0.621-1.129c-5.289-6.666-11.188-13.77-12.486-14.825c-3.064-1.017-13.302-1.173-17.045-1.14
                                    l-0.027-3.636c1.474-0.014,14.508-0.068,18.502,1.425c0.514,0.19,1.875,0.699,14.428,16.574l0.457,0.578l-0.076,0.732
                                    c-0.039,0.382-0.453,3.755-3.007,4.791c-1.243,0.503-2.626,0.329-4.125-0.513c-0.302,0.387-0.652,0.765-1.038,1.054
                                    C277.076,322.104,276.301,322.304,275.295,322.304z"/>
                            </g>
                            <g>
                                <path fill="#584948" d="M281.353,313.851c-0.405,0-0.824-0.053-1.252-0.167l0.942-3.513c0.66,0.178,1.343-0.261,1.868-0.738
                                    c-4.599-5.263-9.493-10.747-10.371-11.526c-5.938-3.534-18.58-5.126-19.746-5.268l-19.148-0.274l0.053-3.637l19.421,0.287
                                    c0.59,0.067,14.552,1.694,21.388,5.831c0.27,0.163,1.092,0.66,12.18,13.384l0.897,1.031l-0.74,1.147
                                    C285.953,311.798,283.902,313.851,281.353,313.851z M272.61,297.947c0.006,0.004,0.011,0.008,0.017,0.012
                                    C272.622,297.955,272.616,297.951,272.61,297.947z"/>
                            </g>
                            <g>
                                <polygon fill="#584948" points="272.212,314.881 264.315,309.699 251.99,310.017 251.895,306.382 265.358,306.034 
                                    274.208,311.841 				"/>
                            </g>
                        </g>
                        <g>
                            <path fill="#584948" d="M243.722,319.211c-0.548-0.076-13.538-1.887-21.548-7.069l1.975-3.056
                                c7.336,4.746,19.937,6.504,20.063,6.521L243.722,319.211z"/>
                        </g>
                        <g>
                            
                                <rect x="383.102" y="211.822" transform="matrix(-0.4228 -0.9062 0.9062 -0.4228 355.5465 653.7399)" fill="#584948" width="5.733" height="3.636"/>
                        </g>
                    </g>
                    <g opacity="0.5">
                        <g>
                            <polygon fill="#5A95B2" points="128.643,306.89 131.003,288.753 86.449,288.753 88.843,306.89 			"/>
                        </g>
                        <g>
                            <polygon fill="#5A95B2" points="89.22,309.747 94.762,351.717 122.692,352.624 128.271,309.747 			"/>
                        </g>
                    </g>
                    <g>
                        <path fill="#FFDAA4" d="M101.945,208.76l59.899-52.291c0,0,3.93,4.232,7.121,8.176l-60.147,53.288L101.945,208.76z"/>
                    </g>
                    <g>
                        <path fill="#CAC3D6" d="M40.304,238.983c0,0-3.756,1.55-8.37,1.203l23.521,68.046l7.272-0.796L40.304,238.983z"/>
                    </g>
                    <g>
                        <path fill="#FFDAA4" d="M32.899,207.145c-7.985,0-14.459,6.473-14.459,14.46c0,7.984,6.474,14.458,14.459,14.458
                            c7.986,0,14.459-6.474,14.459-14.458C47.358,213.618,40.885,207.145,32.899,207.145z M33.537,226.65
                            c-3.051,0-5.525-2.476-5.525-5.525c0-3.052,2.474-5.523,5.525-5.523c3.051,0,5.524,2.473,5.524,5.523
                            C39.061,224.175,36.588,226.65,33.537,226.65z"/>
                    </g>
                    <g>
                        <polygon fill="#CAC3D6" points="93.451,151.468 38.385,204.312 45.36,208.526 98.694,157.524 		"/>
                    </g>
                    <g>
                        <g>
                            <path fill="#5A95B2" d="M3.669,336.336l-2.22-1.798c14.285-17.627,35.475-27.736,58.138-27.736c6.566,0,13.08,0.852,19.362,2.531
                                l-0.739,2.76c-6.041-1.614-12.306-2.434-18.623-2.434C37.79,309.66,17.408,319.382,3.669,336.336z"/>
                        </g>
                        <g>
                            <rect x="0.795" y="343.401" fill="#5A95B2" width="81.304" height="2.854"/>
                        </g>
                        <g>
                            
                                <rect x="-0.143" y="269.51" transform="matrix(-0.3203 -0.9473 0.9473 -0.3203 -205.9763 394.0893)" fill="#5A95B2" width="77.067" height="2.857"/>
                        </g>
                        <g>
                            
                                <rect x="13.189" y="269.942" transform="matrix(-0.3203 -0.9473 0.9473 -0.3203 -189.3944 406.85)" fill="#5A95B2" width="76.143" height="2.856"/>
                        </g>
                        <g>
                            <path fill="#5A95B2" d="M45.106,210.748l-1.977-2.06c0,0,51.801-49.681,52.792-50.631l1.977,2.063
                                C96.907,161.071,45.106,210.748,45.106,210.748z"/>
                        </g>
                        <g>
                            
                                <rect x="61.72" y="134.038" transform="matrix(-0.6921 -0.7218 0.7218 -0.6921 -19.3066 341.3152)" fill="#5A95B2" width="2.856" height="81.474"/>
                        </g>
                        <g>
                            <path fill="#5A95B2" d="M31.934,236.734c-9.703,0-17.597-7.895-17.597-17.596c0-9.702,7.894-17.599,17.597-17.599
                                s17.596,7.896,17.596,17.599C49.53,228.84,41.637,236.734,31.934,236.734z M31.934,204.398c-8.128,0-14.74,6.61-14.74,14.74
                                c0,8.127,6.612,14.736,14.74,14.736c8.127,0,14.739-6.609,14.739-14.736C46.673,211.009,40.061,204.398,31.934,204.398z"/>
                        </g>
                        <g>
                            <path fill="#5A95B2" d="M102.252,162.892l-7.487-7.139c-2.276-2.17-3.572-5.096-3.646-8.239
                                c-0.076-3.146,1.078-6.131,3.248-8.405c2.169-2.277,5.096-3.57,8.239-3.646c3.14-0.076,6.129,1.078,8.405,3.248l8.646,8.297
                                l-1.978,2.063l-8.643-8.293c-1.721-1.643-3.976-2.518-6.362-2.458c-2.38,0.057-4.597,1.037-6.24,2.762
                                c-1.644,1.724-2.517,3.983-2.46,6.364s1.038,4.599,2.761,6.242l7.487,7.138L102.252,162.892z"/>
                        </g>
                        <g>
                            <path fill="#5A95B2" d="M108.696,219.951l-4.746-5.358c-16.11-18.183-14.423-46.083,3.76-62.191
                                c18.184-16.11,46.084-14.422,62.194,3.761l4.746,5.356L108.696,219.951z M136.904,144.194c-9.719,0-19.47,3.409-27.299,10.343
                                c-17.005,15.066-18.582,41.157-3.518,58.161l2.852,3.219l61.678-54.644l-2.852-3.219
                                C159.637,148.879,148.293,144.194,136.904,144.194z"/>
                        </g>
                        <g>
                            <path fill="#5A95B2" d="M142.386,200.936c-3.328,0-6.495-1.223-8.984-3.479l1.919-2.116c2.096,1.901,4.799,2.872,7.621,2.726
                                c2.818-0.146,5.41-1.386,7.301-3.491c3.232-3.604,3.632-8.943,0.973-12.99l2.386-1.567c3.374,5.126,2.866,11.896-1.232,16.466
                                c-2.402,2.676-5.697,4.251-9.28,4.435C142.854,200.931,142.619,200.936,142.386,200.936z"/>
                        </g>
                        <g>
                            
                                <rect x="129.529" y="137.339" transform="matrix(-0.6537 -0.7568 0.7568 -0.6537 81.7413 393.704)" fill="#5A95B2" width="2.856" height="81.619"/>
                        </g>
                        <g>
                            <rect x="171.354" y="190.228" fill="#5A95B2" width="10.744" height="2.856"/>
                        </g>
                        <g>
                            <rect x="142.122" y="216.234" fill="#5A95B2" width="2.856" height="10.742"/>
                        </g>
                        <g>
                            
                                <rect x="159.79" y="209.622" transform="matrix(-0.707 -0.7072 0.7072 -0.707 132.6892 477.0693)" fill="#5A95B2" width="10.745" height="2.857"/>
                        </g>
                        <g>
                            <path fill="#5A95B2" d="M31.934,224.101c-2.737,0-4.963-2.227-4.965-4.961l0,0c-0.002-1.327,0.514-2.574,1.452-3.513
                                c0.938-0.938,2.185-1.456,3.51-1.458c0.001,0,0.001,0,0.003,0c1.325,0,2.57,0.518,3.508,1.454
                                c0.938,0.938,1.456,2.185,1.457,3.51c0,1.326-0.515,2.574-1.452,3.513c-0.938,0.938-2.184,1.455-3.511,1.455H31.934z
                                M29.825,219.138c0.002,1.161,0.947,2.106,2.109,2.106l0,0c0.564,0,1.093-0.221,1.492-0.619c0.397-0.396,0.616-0.927,0.616-1.49
                                c0-0.563-0.219-1.093-0.618-1.489c-0.399-0.398-0.928-0.617-1.49-0.617c-0.001,0-0.002,0-0.002,0
                                c-0.563,0.001-1.092,0.221-1.49,0.619C30.044,218.045,29.825,218.574,29.825,219.138L29.825,219.138z"/>
                        </g>
                    </g>
                    <g className="diamondSt pathSparkle" >
                        <g>
                            <rect x="209.211" y="86.304" fill="#5A95B2" width="2.856" height="9.567"/>
                        </g>
                        <g>
                            <rect x="215.852" y="99.047" fill="#5A95B2" width="9.002" height="2.855"/>
                        </g>
                        <g>
                            <rect x="209.211" y="106.33" fill="#5A95B2" width="2.856" height="9.048"/>
                        </g>
                        <g>
                            <rect x="196.18" y="99.047" fill="#5A95B2" width="9.513" height="2.857"/>
                        </g>
                    </g>
                    <g className="diamondSt2 pathSparkle" >
                        <g>
                            <rect x="13.032" y="267.922" fill="#5A95B2" width="2.855" height="9.568"/>
                        </g>
                        <g>
                            <rect x="19.672" y="280.665" fill="#5A95B2" width="9.002" height="2.856"/>
                        </g>
                        <g>
                            <rect x="13.032" y="287.949" fill="#5A95B2" width="2.855" height="9.048"/>
                        </g>
                        <g>
                            <rect y="280.665" fill="#5A95B2" width="9.512" height="2.858"/>
                        </g>
                    </g>
                    <g className="diamondSt3 pathSparkle" >
                        <g>
                            <rect x="543.486" y="106.64" fill="#5A95B2" width="2.854" height="9.567"/>
                        </g>
                        <g>
                            <rect x="550.125" y="119.38" fill="#5A95B2" width="9.004" height="2.856"/>
                        </g>
                        <g>
                            <rect x="543.484" y="126.665" fill="#5A95B2" width="2.855" height="9.046"/>
                        </g>
                        <g>
                            <rect x="530.453" y="119.38" fill="#5A95B2" width="9.514" height="2.858"/>
                        </g>
                    </g>
                    <g className="diamondSt4 pathSparkle" >
                        <g>
                            
                                <rect x="502.959" y="67.528" transform="matrix(-0.9993 -0.0384 0.0384 -0.9993 1015.5522 157.4272)" fill="#5A95B2" width="12.66" height="2.858"/>
                        </g>
                        <g>
                            
                                <rect x="378.528" y="58.839" transform="matrix(-0.9921 -0.1256 0.1256 -0.9921 759.4089 168.3978)" fill="#5A95B2" width="12.966" height="2.856"/>
                        </g>
                        <g>
                            <g>
                                <path fill="#5A95B2" d="M463,132.88h-34.916c-3.555,0-6.445-2.889-6.445-6.444c0-13.184-3.709-25.699-10.723-36.193
                                    c-5.262-7.871-7.664-17.068-6.951-26.596c1.555-20.796,18.43-37.471,39.252-38.784c11.771-0.744,22.979,3.273,31.543,11.315
                                    c8.447,7.932,13.291,19.12,13.291,30.702c0,7.894-2.199,15.587-6.357,22.251c-6.816,10.917-10.42,23.187-10.42,35.479
                                    C471.273,129.169,467.56,132.88,463,132.88z M445.974,27.631c-0.855,0-1.715,0.027-2.58,0.081
                                    c-19.404,1.226-35.133,16.766-36.584,36.147c-0.662,8.884,1.578,17.459,6.48,24.794c7.33,10.966,11.205,24.033,11.205,37.782
                                    c0,1.979,1.607,3.589,3.588,3.589H463c2.986,0,5.416-2.431,5.416-5.415c0-12.829,3.752-25.619,10.854-36.991
                                    c3.875-6.209,5.926-13.382,5.926-20.739c0-10.955-4.4-21.118-12.391-28.619C465.449,31.355,456.001,27.631,445.974,27.631z"/>
                            </g>
                            <g>
                                <path fill="#5A95B2" d="M458.123,43.419c-1.85-0.747-3.787-1.299-5.752-1.64l0.488-2.815c2.164,0.376,4.297,0.984,6.332,1.806
                                    L458.123,43.419z"/>
                            </g>
                            <g>
                                <path fill="#5A95B2" d="M418.986,67.705l-2.85-0.212c1.158-15.51,13.746-27.944,29.279-28.925
                                    c0.686-0.043,1.379-0.065,2.055-0.065v2.857c-0.617,0-1.25,0.021-1.875,0.059C431.478,42.31,420.039,53.61,418.986,67.705z"/>
                            </g>
                            <g>
                                <path fill="#5A95B2" d="M469.541,80.51h-4.156v-2.855h4.156V80.51z M461.228,80.51h-4.154v-2.855h4.154V80.51z M452.917,80.51
                                    h-4.154v-2.855h4.154V80.51z M444.607,80.51h-4.156v-2.855h4.156V80.51z M436.294,80.51h-4.156v-2.855h4.156V80.51z
                                    M427.986,80.51h-4.156v-2.855h4.156V80.51z"/>
                            </g>
                            <g>
                                
                                    <rect x="453.831" y="88.653" transform="matrix(-0.9842 -0.1768 0.1768 -0.9842 883.9852 297.7505)" fill="#5A95B2" width="2.856" height="41.676"/>
                            </g>
                            <g>
                                
                                    <rect x="414.416" y="108.069" transform="matrix(-0.1816 -0.9834 0.9834 -0.1816 406.6267 557.3976)" fill="#5A95B2" width="41.671" height="2.858"/>
                            </g>
                            <g>
                                <path fill="#5A95B2" d="M445.947,110.509c-7.486,0-14.852-1.97-21.297-5.692l1.43-2.475c6.012,3.476,12.881,5.313,19.867,5.313
                                    c6.854,0,13.609-1.772,19.539-5.125l1.406,2.487C460.537,108.609,453.292,110.509,445.947,110.509z"/>
                            </g>
                            <g>
                                
                                    <rect x="490.72" y="103.054" transform="matrix(-0.7965 -0.6047 0.6047 -0.7965 830.0392 488.3427)" fill="#5A95B2" width="12.967" height="2.857"/>
                            </g>
                            <g>
                                
                                    <rect x="497.627" y="26.416" transform="matrix(-0.5414 -0.8408 0.8408 -0.5414 741.5724 470.3049)" fill="#5A95B2" width="2.857" height="12.966"/>
                            </g>
                            <g>
                                
                                    <rect x="468.458" y="2.766" transform="matrix(-0.9262 -0.377 0.377 -0.9262 901.6104 194.9819)" fill="#5A95B2" width="2.859" height="12.967"/>
                            </g>
                            <g>
                                
                                    <rect x="425.969" y="5.229" transform="matrix(-0.2216 -0.9751 0.9751 -0.2216 521.7851 429.8334)" fill="#5A95B2" width="12.966" height="2.856"/>
                            </g>
                            <g>
                                
                                    <rect x="393.79" y="24.538" transform="matrix(-0.7408 -0.6718 0.6718 -0.7408 679.3361 314.0895)" fill="#5A95B2" width="12.964" height="2.856"/>
                            </g>
                            <g>
                                
                                    <rect x="390.748" y="90.633" transform="matrix(-0.4663 -0.8847 0.8847 -0.4663 489.1165 489.3351)" fill="#5A95B2" width="2.857" height="12.966"/>
                            </g>
                            <g>
                                <path fill="#5A95B2" d="M464.167,145.209h-35.881c-3.67,0-6.654-2.982-6.654-6.65v-1.882c0-3.668,2.984-6.652,6.654-6.652
                                    h35.881c3.67,0,6.652,2.984,6.652,6.652v1.882C470.82,142.227,467.837,145.209,464.167,145.209z M428.287,132.88
                                    c-2.094,0-3.797,1.704-3.797,3.797v1.882c0,2.093,1.703,3.795,3.797,3.795h35.881c2.094,0,3.795-1.702,3.795-3.795v-1.882
                                    c0-2.093-1.701-3.797-3.795-3.797H428.287z"/>
                            </g>
                            <g>
                                <path fill="#5A95B2" d="M458.546,157.539h-24.641c-3.668,0-6.652-2.984-6.652-6.65v-1.884c0-3.667,2.984-6.65,6.652-6.65h24.641
                                    c3.668,0,6.652,2.984,6.652,6.65v1.884C465.199,154.554,462.214,157.539,458.546,157.539z M433.908,145.209
                                    c-2.094,0-3.797,1.702-3.797,3.795v1.883c0,2.092,1.703,3.794,3.797,3.794h24.641c2.092,0,3.795-1.703,3.795-3.794v-1.883
                                    c0-2.093-1.703-3.795-3.795-3.795H433.908z"/>
                            </g>
                            <g>
                                <path fill="#5A95B2" d="M451.892,169.869H440.56c-3.668,0-6.65-2.984-6.65-6.653v-1.881c0-3.668,2.982-6.652,6.65-6.652h11.332
                                    c3.666,0,6.65,2.984,6.65,6.652v1.881C458.542,166.884,455.558,169.869,451.892,169.869z M440.56,157.539
                                    c-2.092,0-3.795,1.703-3.795,3.795v1.881c0,2.094,1.703,3.795,3.795,3.795h11.332c2.094,0,3.795-1.701,3.795-3.795v-1.881
                                    c0-2.093-1.701-3.795-3.795-3.795H440.56z"/>
                            </g>
                        </g>
                    </g>
                    <g>
                        <path fill="#5A95B2" d="M405.072,364.287h-4.156v-2.854h4.156V364.287z M396.759,364.287h-4.152v-2.854h4.152V364.287z
                            M388.451,364.287h-4.156v-2.854h4.156V364.287z M380.14,364.287h-4.156v-2.854h4.156V364.287z M371.828,364.287h-4.154v-2.854
                            h4.154V364.287z M363.517,364.287h-4.156v-2.854h4.156V364.287z M355.207,364.287h-4.154v-2.854h4.154V364.287z M346.894,364.287
                            h-4.154v-2.854h4.154V364.287z M338.585,364.287h-4.158v-2.854h4.158V364.287z M330.273,364.287h-4.154v-2.854h4.154V364.287z
                            M321.962,364.287h-4.156v-2.854h4.156V364.287z M313.652,364.287h-4.154v-2.854h4.154V364.287z M305.339,364.287h-4.154v-2.854
                            h4.154V364.287z M297.031,364.287h-4.156v-2.854h4.156V364.287z M288.716,364.287h-4.154v-2.854h4.154V364.287z M280.408,364.287
                            h-4.156v-2.854h4.156V364.287z M272.097,364.287h-4.156v-2.854h4.156V364.287z M263.785,364.287h-4.155v-2.854h4.155V364.287z
                            M255.475,364.287h-4.156v-2.854h4.156V364.287z M247.164,364.287h-4.156v-2.854h4.156V364.287z M238.853,364.287h-4.156v-2.854
                            h4.156V364.287z M230.542,364.287h-4.154v-2.854h4.154V364.287z M222.23,364.287h-4.155v-2.854h4.155V364.287z M213.92,364.287
                            h-4.156v-2.854h4.156V364.287z M205.609,364.287h-4.156v-2.854h4.156V364.287z M197.298,364.287h-4.156v-2.854h4.156V364.287z
                            M188.987,364.287h-4.155v-2.854h4.155V364.287z M180.676,364.287h-4.155v-2.854h4.155V364.287z M172.365,364.287h-4.156v-2.854
                            h4.156V364.287z M164.055,364.287h-4.156v-2.854h4.156V364.287z M155.744,364.287h-4.156v-2.854h4.156V364.287z M147.432,364.287
                            h-4.155v-2.854h4.155V364.287z M139.121,364.287h-4.155v-2.854h4.155V364.287z M130.811,364.287h-4.156v-2.854h4.156V364.287z
                            M122.5,364.287h-4.157v-2.854h4.157V364.287z M114.189,364.287h-4.156v-2.854h4.156V364.287z"/>
                    </g>
                    <g>
                        <path fill="#5A95B2" d="M123.959,354.054H86.956l-9.041-72.14h54.699L123.959,354.054z M89.477,351.196h31.947l7.969-66.422
                            H81.152L89.477,351.196z"/>
                    </g>
                    <g>
                        <g>
                            <polygon fill="#5A95B2" points="96.548,283.675 90.478,258.2 80.553,239.753 80.013,260.693 85.332,283.012 82.552,283.675 
                                77.148,260.993 77.975,228.932 93.171,257.175 93.214,257.356 99.328,283.012 			"/>
                        </g>
                        <g>
                            
                                <rect x="80.676" y="242.857" transform="matrix(-0.232 -0.9727 0.9727 -0.232 -138.1155 382.9036)" fill="#5A95B2" width="2.856" height="6.24"/>
                        </g>
                        <g>
                            
                                <rect x="76.675" y="270.648" transform="matrix(-0.2317 -0.9728 0.9728 -0.2317 -155.9775 420.9642)" fill="#5A95B2" width="23.145" height="2.856"/>
                        </g>
                        <g>
                            <path fill="#5A95B2" d="M82.965,263.538c-3.206,0-5.231-1.544-5.268-1.571l1.767-2.246l-0.883,1.124l0.873-1.132
                                c0.193,0.146,4.823,3.511,11.36-3.034l2.021,2.019C89.045,262.491,85.605,263.538,82.965,263.538z"/>
                        </g>
                    </g>
                    <g>
                        <g>
                            <polygon fill="#5A95B2" points="110.393,283.744 107.651,282.944 112.599,265.955 112.695,265.796 129.174,238.499 
                                128.415,270.561 124.575,283.744 121.832,282.946 125.566,270.121 126.063,249.181 115.238,267.114 			"/>
                        </g>
                        <g>
                            
                                <rect x="121.207" y="253.912" transform="matrix(-0.9602 -0.2792 0.2792 -0.9602 172.4118 535.2413)" fill="#5A95B2" width="6.239" height="2.859"/>
                        </g>
                        <g>
                            <path fill="#5A95B2" d="M122.228,272.475c-7.332,0-9.606-5.337-9.632-5.402l2.641-1.091c0.096,0.226,2.455,5.438,11.375,2.98
                                l0.759,2.755C125.429,272.252,123.723,272.475,122.228,272.475z"/>
                        </g>
                    </g>
                    <g>
                        <rect x="82.342" y="304.332" fill="#5A95B2" width="45.277" height="2.855"/>
                    </g>
                    <g>
                        <path fill="#5A95B2" d="M122.533,325.236h-4.157v-2.857h4.157V325.236z M114.222,325.236h-4.156v-2.857h4.156V325.236z
                            M105.911,325.236h-4.155v-2.857h4.155V325.236z M97.599,325.236h-4.154v-2.857h4.154V325.236z M89.289,325.236h-4.156v-2.857
                            h4.156V325.236z"/>
                    </g>
                    <g>
                        <polygon opacity="0.3" fill="#5A95B2" enableBackground="new    " points="519.003,215.919 519.761,216.885 519.761,216.539 
                            568.941,216.539 568.941,215.919 		"/>
                        <polygon opacity="0.3" fill="#5A95B2" enableBackground="new    " points="568.941,213.987 568.941,206.995 512.011,206.995 
                            517.63,214.167 517.63,213.987 		"/>
                        <polygon opacity="0.3" fill="#5A95B2" enableBackground="new    " points="568.941,218.469 521.001,218.469 527.64,226.947 
                            568.941,226.947 		"/>
                        <polygon opacity="0.3" fill="#5A95B2" enableBackground="new    " points="549.455,254.796 549.939,255.416 568.941,255.416 
                            568.941,254.796 		"/>
                        <polygon opacity="0.3" fill="#5A95B2" enableBackground="new    " points="539.304,241.837 539.789,242.457 568.941,242.457 
                            568.941,241.837 		"/>
                        <polygon opacity="0.3" fill="#5A95B2" enableBackground="new    " points="568.941,244.389 541.3,244.389 547.943,252.866 
                            568.941,252.866 		"/>
                        <polygon opacity="0.3" fill="#5A95B2" enableBackground="new    " points="568.941,257.349 551.453,257.349 558.089,265.824 
                            568.941,265.824 		"/>
                        <polygon opacity="0.3" fill="#5A95B2" enableBackground="new    " points="529.154,228.879 529.638,229.499 568.941,229.499 
                            568.941,228.879 		"/>
                        <polygon opacity="0.3" fill="#5A95B2" enableBackground="new    " points="568.941,270.308 561.601,270.308 568.24,278.784 
                            568.941,278.784 		"/>
                        <polygon opacity="0.3" fill="#5A95B2" enableBackground="new    " points="559.603,267.756 560.089,268.375 568.941,268.375 
                            568.941,267.756 		"/>
                        <polygon opacity="0.3" fill="#5A95B2" enableBackground="new    " points="568.941,231.429 531.152,231.429 537.792,239.906 
                            568.941,239.906 		"/>
                        <path fill="#5A95B2" d="M557.585,327.146c-0.037,0.646-0.086,1.292-0.146,1.932h11.502v-1.932H557.585z"/>
                        <path fill="#5A95B2" d="M505.384,200.152v78.833c0.643-0.089,1.281-0.17,1.932-0.232v-76.668h61.625v-1.933H505.384z"/>
                        <polygon fill="#5A95B2" points="568.941,213.987 517.63,213.987 517.63,214.167 517.63,215.919 519.003,215.919 568.941,215.919 
                                    "/>
                        <polygon fill="#5A95B2" points="568.941,226.947 527.64,226.947 517.63,226.947 517.63,228.879 529.154,228.879 568.941,228.879 
                                    "/>
                        <polygon fill="#5A95B2" points="568.941,239.906 537.792,239.906 528.869,239.906 528.869,241.837 539.304,241.837 
                            568.941,241.837 		"/>
                        <polygon fill="#5A95B2" points="568.941,252.866 547.943,252.866 528.869,252.866 528.869,254.796 549.455,254.796 
                            568.941,254.796 		"/>
                        <polygon fill="#5A95B2" points="568.941,265.824 558.089,265.824 517.63,265.824 517.63,267.756 559.603,267.756 568.941,267.756 
                                    "/>
                        <path fill="#5A95B2" d="M568.24,278.784h-43.063v1.767c0.172,0.055,0.348,0.111,0.52,0.166h43.244v-1.933H568.24L568.24,278.784z"
                            />
                        <path fill="#5A95B2" d="M543.917,291.744c0.637,0.627,1.26,1.271,1.861,1.933h23.16v-1.933H543.917z"/>
                        <path fill="#5A95B2" d="M553.185,304.704c0.307,0.636,0.598,1.278,0.873,1.931h14.883v-1.931H553.185z"/>
                        <path fill="#5A95B2" d="M556.626,334.185c-0.141,0.647-0.287,1.293-0.455,1.931h12.77v-1.931H556.626z"/>
                        <path fill="#5A95B2" d="M489.683,220.015h6.621v61.19c0.639-0.229,1.281-0.445,1.932-0.646v-67.218h6.283v-1.931h-8.215v6.673
                            h-8.553v67.22c0.635-0.39,1.279-0.766,1.932-1.122V220.015z"/>
                        <path fill="#FFFFFF" d="M511.363,318.695c-0.002,0-0.004,0-0.004,0c-3.748,0-6.801,3.05-6.803,6.797
                            c-0.002,1.815,0.707,3.523,1.988,4.809c1.285,1.285,2.992,1.994,4.809,1.994c0,0,0.004,0,0.006,0c1.813,0,3.518-0.706,4.805-1.987
                            c1.283-1.284,1.994-2.99,1.994-4.808c0-1.816-0.707-3.523-1.99-4.809C514.884,319.405,513.175,318.697,511.363,318.695z"/>
                        <path fill="#FFFFFF" d="M511.722,287.054c-20.637,0-37.428,16.788-37.428,37.428c0,20.638,16.791,37.428,37.428,37.428
                            c20.641,0,37.428-16.79,37.428-37.428C549.15,303.843,532.363,287.054,511.722,287.054z M483.513,325.861l-2.854,0.11
                            c-0.572-14.729,9.502-27.607,23.953-30.622l0.582,2.797C492.115,300.875,482.996,312.531,483.513,325.861z M511.359,335.152
                            c-0.002,0-0.006,0-0.01,0c-1.959-0.002-3.826-0.59-5.412-1.669l-14.129,14.128l-2.02-2.021l14.076-14.071
                            c-1.379-1.712-2.166-3.801-2.166-6.029c0.004-4.832,3.59-8.811,8.232-9.507V292.8h2.857v23.179c2.033,0.305,3.918,1.211,5.4,2.693
                            c1.82,1.824,2.824,4.25,2.82,6.83C521.009,330.824,516.679,335.152,511.359,335.152z M541.845,324.482
                            c0,16.609-13.514,30.123-30.123,30.123v-2.858c15.035,0,27.268-12.229,27.268-27.265H541.845z"/>
                        <path fill="#5A95B2" d="M511.722,284.197c-22.213,0-40.283,18.073-40.283,40.285c0,22.213,18.07,40.285,40.283,40.285
                            s40.285-18.072,40.285-40.285C552.007,302.27,533.935,284.197,511.722,284.197z M511.722,361.91
                            c-20.637,0-37.428-16.79-37.428-37.428c0-20.64,16.791-37.428,37.428-37.428c20.641,0,37.428,16.788,37.428,37.428
                            C549.15,345.12,532.363,361.91,511.722,361.91z"/>
                        <path fill="#5A95B2" d="M521.011,325.501c0.004-2.58-1-5.006-2.82-6.83c-1.482-1.482-3.367-2.39-5.4-2.693v-23.179h-2.857v23.183
                            c-4.645,0.696-8.229,4.675-8.232,9.507c0,2.229,0.787,4.317,2.166,6.029l-14.076,14.071l2.02,2.021l14.129-14.128
                            c1.586,1.079,3.453,1.667,5.412,1.669c0.004,0,0.008,0,0.01,0C516.679,335.152,521.009,330.824,521.011,325.501z M516.164,330.306
                            c-1.287,1.281-2.99,1.987-4.805,1.987c-0.002,0-0.004,0-0.004,0c-1.816,0-3.525-0.709-4.811-1.994
                            c-1.281-1.284-1.99-2.992-1.988-4.809c0.002-3.747,3.055-6.797,6.803-6.797c0,0,0.002,0,0.004,0
                            c1.813,0.002,3.521,0.71,4.805,1.996c1.283,1.284,1.99,2.992,1.99,4.809C518.158,327.316,517.447,329.022,516.164,330.306z"/>
                        <path fill="#5A95B2" d="M505.195,298.146l-0.582-2.797c-14.451,3.015-24.525,15.894-23.953,30.622l2.854-0.11
                            C482.996,312.531,492.115,300.875,505.195,298.146z"/>
                        <path fill="#5A95B2" d="M511.722,351.747v2.858c16.609,0,30.123-13.514,30.123-30.123h-2.855
                            C538.99,339.517,526.757,351.747,511.722,351.747z"/>
                    </g>
                </g>
                </svg>
    )
}

export default HomeImage
