export const DraftrrLogo = ({width}) => {
    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
                width={width}  viewBox="0 0 226.525 69.172" enableBackground="new 0 0 226.525 69.172">
            <g>
                <g>
                    <g>
                        <path fill="#969696" d="M25.694,62.297c-0.105,0-0.289-0.131-0.552-0.395c-0.264-0.264-0.394-0.447-0.394-0.551
                            c0.051-0.631,0.13-1.393,0.236-2.285c0.104-0.895,0.157-1.656,0.157-2.287v-0.473c-0.894,1.367-2.221,2.641-3.98,3.822
                            c-1.761,1.184-3.876,1.773-6.345,1.773c-2.995,0-5.596-0.775-7.803-2.324s-3.929-3.559-5.162-6.029
                            C0.616,51.079,0,48.452,0,45.666c0-3.204,0.735-6.173,2.207-8.906c1.47-2.73,3.572-4.926,6.306-6.58
                            c2.73-1.656,5.937-2.483,9.615-2.483c0.945,0,1.931,0.093,2.955,0.276c1.025,0.184,1.826,0.381,2.404,0.591
                            c0.578,0.157,0.919,0.236,1.024,0.236c0.367,0,0.552-0.552,0.552-1.655V16.662c0-2.049-0.314-3.469-0.945-4.256
                            c-0.631-0.789-1.551-1.287-2.759-1.498c-0.526-0.104-0.788-0.473-0.788-1.104c0-0.524,0.236-0.867,0.709-1.024
                            c2.207-0.524,4.099-1.076,5.675-1.655c1.577-0.577,2.837-1.051,3.783-1.418c0.367-0.21,0.657-0.328,0.867-0.355
                            c0.21-0.025,0.367-0.039,0.473-0.039c0.42,0,0.631,0.211,0.631,0.631c0,0.157-0.027,0.578-0.079,1.261
                            c-0.053,0.684-0.132,1.655-0.236,2.916c-0.105,1.261-0.158,2.812-0.158,4.65l-0.157,37.122c0,1.787,0.131,3.074,0.394,3.863
                            c0.264,0.787,0.71,1.182,1.341,1.182c0.263,0,0.656-0.025,1.183-0.078c0.523-0.053,1.233-0.105,2.127-0.158
                            c0.84-0.053,1.262,0.211,1.262,0.789c0,0.367-0.132,0.723-0.395,1.063c-0.264,0.344-0.525,0.514-0.788,0.514
                            c-1.629,0.209-3.271,0.525-4.926,0.945s-3.403,1.023-5.241,1.813c-0.422,0.209-0.709,0.342-0.867,0.395
                            C26.009,62.27,25.853,62.297,25.694,62.297z M18.049,57.332c1.155,0,2.377-0.303,3.665-0.906
                            c1.287-0.604,2.377-1.406,3.271-2.404V38.652c0-1.209-0.383-2.43-1.144-3.666c-0.763-1.233-1.8-2.232-3.113-2.994
                            s-2.812-1.143-4.492-1.143c-1.21,0-2.497,0.407-3.862,1.221c-1.367,0.815-2.521,2.168-3.468,4.06s-1.419,4.44-1.419,7.646
                            c0,2.68,0.367,4.9,1.104,6.66c0.735,1.76,1.668,3.14,2.799,4.137c1.129,1,2.297,1.709,3.507,2.129
                            C16.104,57.123,17.155,57.332,18.049,57.332z"/>
                        <path fill="#969696" d="M42.403,61.035c-0.473,0-0.855-0.078-1.143-0.236c-0.29-0.156-0.434-0.393-0.434-0.709
                            c0-0.42,0.17-0.721,0.512-0.906c0.342-0.184,0.774-0.354,1.301-0.512c1.104-0.262,1.878-0.631,2.324-1.104s0.67-1.104,0.67-1.893
                            v-17.34c0-1.313-0.184-2.43-0.551-3.35c-0.369-0.918-1.156-1.51-2.365-1.773c-0.21-0.051-0.407-0.184-0.591-0.394
                            c-0.185-0.21-0.276-0.473-0.276-0.788c0-0.578,0.263-0.945,0.789-1.104c1.471-0.42,3.047-1.012,4.729-1.773
                            c1.682-0.761,3.047-1.431,4.099-2.01c0.315-0.209,0.604-0.367,0.867-0.473c0.263-0.105,0.446-0.158,0.552-0.158
                            c0.367,0,0.551,0.211,0.551,0.631c0,0.211-0.013,0.658-0.039,1.34c-0.027,0.684-0.066,1.445-0.117,2.285
                            c-0.054,0.842-0.08,1.656-0.08,2.443c0,0.211,0.053,0.316,0.158,0.316c0.84-1.051,1.773-2.022,2.798-2.917
                            c1.024-0.893,2.114-1.616,3.271-2.167c1.156-0.552,2.26-0.828,3.311-0.828c1.366,0,2.417,0.276,3.153,0.828
                            c0.734,0.551,1.104,1.274,1.104,2.167c0,0.579-0.172,1.249-0.513,2.011c-0.343,0.762-0.788,1.418-1.34,1.97
                            s-1.143,0.827-1.773,0.827c-0.368,0-0.762-0.091-1.182-0.275c-0.422-0.184-0.867-0.381-1.34-0.592
                            c-0.422-0.209-0.855-0.406-1.301-0.59c-0.447-0.184-0.881-0.276-1.301-0.276c-1.314,0-2.521,0.473-3.625,1.419
                            c-1.104,0.945-1.656,2.785-1.656,5.517v14.817c0,0.842,0.342,1.498,1.025,1.971c0.682,0.473,1.707,0.92,3.074,1.34
                            c0.577,0.158,1.023,0.33,1.34,0.512c0.314,0.186,0.473,0.461,0.473,0.828c0,0.631-0.5,0.945-1.498,0.945
                            c-1.576,0-2.93-0.066-4.059-0.195c-1.131-0.131-2.379-0.197-3.744-0.197c-0.895,0-1.682,0.027-2.364,0.078
                            c-0.684,0.053-1.394,0.119-2.128,0.197C44.347,60.996,43.454,61.035,42.403,61.035z"/>
                        <path fill="#969696" d="M76.846,61.902c-1.104,0-2.182-0.354-3.231-1.063c-1.052-0.711-1.919-1.604-2.602-2.68
                            c-0.684-1.078-1.024-2.141-1.024-3.193c0-1.734,0.485-3.125,1.458-4.178c0.972-1.05,2.482-1.996,4.532-2.837l7.646-3.231
                            c1.05-0.42,1.681-0.801,1.892-1.143c0.209-0.342,0.341-1.037,0.394-2.089l0.158-4.808c0.052-1.419-0.29-2.602-1.025-3.547
                            c-0.736-0.945-1.813-1.419-3.231-1.419c-0.736,0-1.485,0.146-2.246,0.434c-0.762,0.29-1.379,0.618-1.853,0.985
                            c-0.263,0.211-0.433,0.5-0.512,0.867c-0.079,0.368-0.118,0.762-0.118,1.182c0,0.264,0.012,0.565,0.039,0.906
                            c0.026,0.343,0.04,0.619,0.04,0.828c0,0.314-0.355,0.697-1.064,1.143c-0.709,0.447-1.524,0.855-2.443,1.222
                            c-0.92,0.368-1.694,0.552-2.325,0.552c-0.315,0-0.565-0.091-0.749-0.275c-0.185-0.184-0.275-0.434-0.275-0.749
                            c0-0.84,0.275-1.721,0.827-2.641c0.552-0.919,1.38-1.853,2.483-2.798c1.261-1.104,2.64-2.088,4.138-2.955
                            c1.497-0.867,2.968-1.537,4.414-2.01c1.444-0.474,2.719-0.71,3.822-0.71c2.207,0,4.006,0.749,5.398,2.246
                            c1.392,1.497,2.036,3.429,1.932,5.794l-0.395,17.813c-0.053,1.156,0.184,2.102,0.71,2.838c0.524,0.736,1.182,1.104,1.97,1.104
                            c1.208,0,2.154-0.316,2.838-0.947c0.209-0.209,0.394-0.314,0.552-0.314c0.262,0,0.485,0.078,0.67,0.236
                            c0.184,0.158,0.275,0.367,0.275,0.631c0,0.631-0.368,1.287-1.104,1.971c-0.894,0.893-1.852,1.59-2.877,2.088
                            c-1.024,0.498-2.01,0.748-2.955,0.748c-2.628,0-4.729-1.418-6.306-4.256h-0.236c-1.733,1.473-3.363,2.549-4.887,3.232
                            C80.05,61.561,78.474,61.902,76.846,61.902z M80.944,57.646c0.893,0,1.642-0.145,2.246-0.434
                            c0.604-0.287,1.143-0.695,1.615-1.221c0.263-0.264,0.46-0.592,0.592-0.986c0.13-0.394,0.223-1.011,0.275-1.852l0.157-4.492
                            c0.052-0.525,0.026-0.906-0.078-1.144c-0.106-0.236-0.315-0.354-0.631-0.354c-0.105,0-0.264,0.027-0.473,0.078
                            c-0.211,0.054-0.5,0.158-0.867,0.316c-2.364,0.894-4.02,1.826-4.966,2.797c-0.945,0.974-1.418,2.037-1.418,3.193
                            c0,1.418,0.38,2.457,1.143,3.113C79.301,57.318,80.103,57.646,80.944,57.646z"/>
                        <path fill="#969696" d="M103.248,61.035c-0.473,0-0.854-0.078-1.143-0.236c-0.289-0.156-0.434-0.393-0.434-0.709
                            c0-0.42,0.158-0.721,0.474-0.906c0.314-0.184,0.709-0.328,1.182-0.434c1.313-0.314,2.207-0.709,2.68-1.182s0.71-1.209,0.71-2.207
                            V33.056c-0.526,0-1.131-0.013-1.813-0.039c-0.684-0.026-1.445-0.065-2.285-0.118c-0.211-0.052-0.447-0.275-0.71-0.67
                            s-0.394-0.749-0.394-1.064c0-0.104,0.079-0.209,0.236-0.314s0.288-0.184,0.395-0.237c0.84-0.42,1.615-0.8,2.324-1.143
                            c0.71-0.341,1.459-0.696,2.247-1.063c0.104-4.466,0.761-8.197,1.97-11.192c1.208-2.995,2.745-5.372,4.611-7.133
                            c1.864-1.761,3.835-3.021,5.911-3.783c2.075-0.761,4.032-1.144,5.872-1.144c3.152,0,5.478,0.461,6.975,1.38
                            c1.498,0.92,2.246,2.194,2.246,3.822c0,1.104-0.263,1.958-0.788,2.562c-0.525,0.605-1.209,0.906-2.049,0.906
                            c-0.473,0-0.92-0.197-1.34-0.591c-0.421-0.394-0.895-0.854-1.419-1.38c-0.736-0.787-1.643-1.536-2.719-2.246
                            c-1.078-0.709-2.483-1.063-4.217-1.063c-2.364,0-4.256,1.013-5.675,3.034c-1.419,2.023-2.128,5.189-2.128,9.498v6.777
                            c0,0.369,0.104,0.605,0.315,0.71c0.209,0.106,0.445,0.157,0.709,0.157h9.458c0.315,0,0.577,0.105,0.788,0.315
                            c0.209,0.21,0.315,0.447,0.315,0.71c0,0.42-0.039,0.854-0.118,1.3c-0.079,0.447-0.197,0.827-0.354,1.144
                            c-0.211,0.42-0.447,0.63-0.71,0.63h-8.434c-0.841,0-1.379,0.118-1.615,0.354s-0.354,0.565-0.354,0.985v20.729
                            c0,1.156,0.327,1.998,0.985,2.521c0.656,0.525,2.062,0.945,4.217,1.262c1.103,0.211,1.654,0.658,1.654,1.34
                            c0,0.314-0.132,0.564-0.394,0.748c-0.264,0.184-0.604,0.275-1.024,0.275c-1.577,0-3.113-0.066-4.611-0.195
                            c-1.497-0.131-2.93-0.197-4.295-0.197c-0.895,0-1.695,0.027-2.404,0.078c-0.709,0.053-1.433,0.119-2.167,0.197
                            C105.191,60.996,104.299,61.035,103.248,61.035z"/>
                        <path fill="#969696" d="M141.869,61.902c-2.891,0-5.111-0.814-6.66-2.443c-1.551-1.627-2.325-4.02-2.325-7.172V34.002
                            c0-0.42-0.171-0.723-0.513-0.906s-1.012-0.276-2.01-0.276h-0.63c-0.315,0-0.579-0.223-0.789-0.67
                            c-0.21-0.446-0.314-0.88-0.314-1.3c0-0.105,0.064-0.25,0.196-0.434c0.131-0.184,0.249-0.303,0.355-0.355
                            c1.104-0.682,2.193-1.431,3.271-2.246c1.076-0.814,2.075-1.642,2.995-2.482c0.919-0.84,1.694-1.576,2.325-2.207
                            c0.315-0.262,0.577-0.499,0.788-0.709c0.209-0.21,0.473-0.315,0.788-0.315s0.591,0.079,0.827,0.236
                            c0.237,0.157,0.328,0.473,0.276,0.946l-0.473,3.861c-0.054,0.631,0.091,1.052,0.434,1.262c0.341,0.21,0.827,0.314,1.458,0.314
                            h9.143c0.157,0,0.315,0.186,0.473,0.552c0.157,0.368,0.236,0.737,0.236,1.104c0,0.579-0.079,1.131-0.236,1.655
                            c-0.157,0.525-0.343,0.788-0.552,0.788h-7.646c-1.313,0-2.167,0.132-2.562,0.394c-0.394,0.264-0.591,0.867-0.591,1.813v15.29
                            c0,2.207,0.446,3.902,1.34,5.084c0.893,1.182,2.154,1.773,3.783,1.773c1.523,0,2.68-0.131,3.468-0.395
                            c0.788-0.262,1.498-0.576,2.128-0.945c0.158-0.105,0.288-0.158,0.395-0.158c0.263,0,0.434,0.133,0.513,0.395
                            c0.078,0.264,0.118,0.553,0.118,0.867s-0.474,0.867-1.419,1.654c-0.946,0.789-2.182,1.537-3.704,2.248
                            C145.23,61.549,143.603,61.902,141.869,61.902z"/>
                        <path fill="#574847" d="M156.134,61.035c-0.474,0-0.855-0.078-1.144-0.236c-0.289-0.156-0.434-0.393-0.434-0.709
                            c0-0.42,0.17-0.721,0.513-0.906c0.341-0.184,0.774-0.354,1.301-0.512c1.104-0.262,1.878-0.631,2.325-1.104
                            c0.445-0.473,0.67-1.104,0.67-1.893v-17.34c0-1.313-0.186-2.43-0.552-3.35c-0.368-0.918-1.156-1.51-2.365-1.773
                            c-0.21-0.051-0.407-0.184-0.591-0.394c-0.185-0.21-0.275-0.473-0.275-0.788c0-0.578,0.262-0.945,0.788-1.104
                            c1.47-0.42,3.047-1.012,4.729-1.773c1.682-0.761,3.047-1.431,4.099-2.01c0.315-0.209,0.604-0.367,0.867-0.473
                            c0.262-0.105,0.445-0.158,0.552-0.158c0.367,0,0.552,0.211,0.552,0.631c0,0.211-0.014,0.658-0.04,1.34
                            c-0.026,0.684-0.066,1.445-0.118,2.285c-0.053,0.842-0.078,1.656-0.078,2.443c0,0.211,0.052,0.316,0.157,0.316
                            c0.84-1.051,1.773-2.022,2.798-2.917c1.024-0.893,2.114-1.616,3.271-2.167c1.154-0.552,2.258-0.828,3.31-0.828
                            c1.366,0,2.417,0.276,3.153,0.828c0.734,0.551,1.104,1.274,1.104,2.167c0,0.579-0.172,1.249-0.513,2.011
                            c-0.343,0.762-0.788,1.418-1.34,1.97s-1.143,0.827-1.773,0.827c-0.368,0-0.763-0.091-1.183-0.275
                            c-0.421-0.184-0.866-0.381-1.34-0.592c-0.421-0.209-0.854-0.406-1.3-0.59c-0.447-0.184-0.881-0.276-1.301-0.276
                            c-1.314,0-2.522,0.473-3.626,1.419c-1.104,0.945-1.655,2.785-1.655,5.517v14.817c0,0.842,0.342,1.498,1.025,1.971
                            c0.682,0.473,1.707,0.92,3.073,1.34c0.578,0.158,1.025,0.33,1.34,0.512c0.315,0.186,0.474,0.461,0.474,0.828
                            c0,0.631-0.5,0.945-1.498,0.945c-1.576,0-2.93-0.066-4.059-0.195c-1.131-0.131-2.378-0.197-3.744-0.197
                            c-0.895,0-1.683,0.027-2.364,0.078c-0.684,0.053-1.393,0.119-2.128,0.197C158.077,60.996,157.184,61.035,156.134,61.035z"/>
                        <path fill="#574847" d="M184.666,61.035c-0.474,0-0.855-0.078-1.144-0.236c-0.289-0.156-0.434-0.393-0.434-0.709
                            c0-0.42,0.17-0.721,0.513-0.906c0.341-0.184,0.774-0.354,1.301-0.512c1.104-0.262,1.878-0.631,2.325-1.104
                            c0.445-0.473,0.67-1.104,0.67-1.893v-17.34c0-1.313-0.186-2.43-0.552-3.35c-0.368-0.918-1.156-1.51-2.365-1.773
                            c-0.21-0.051-0.407-0.184-0.591-0.394c-0.185-0.21-0.275-0.473-0.275-0.788c0-0.578,0.262-0.945,0.788-1.104
                            c1.47-0.42,3.047-1.012,4.729-1.773c1.682-0.761,3.047-1.431,4.099-2.01c0.315-0.209,0.604-0.367,0.867-0.473
                            c0.262-0.105,0.445-0.158,0.552-0.158c0.367,0,0.552,0.211,0.552,0.631c0,0.211-0.014,0.658-0.04,1.34
                            c-0.026,0.684-0.066,1.445-0.118,2.285c-0.053,0.842-0.078,1.656-0.078,2.443c0,0.211,0.052,0.316,0.157,0.316
                            c0.84-1.051,1.773-2.022,2.798-2.917c1.024-0.893,2.114-1.616,3.271-2.167c1.154-0.552,2.258-0.828,3.31-0.828
                            c1.366,0,2.417,0.276,3.153,0.828c0.734,0.551,1.104,1.274,1.104,2.167c0,0.579-0.172,1.249-0.513,2.011
                            c-0.343,0.762-0.788,1.418-1.34,1.97s-1.143,0.827-1.773,0.827c-0.368,0-0.763-0.091-1.183-0.275
                            c-0.421-0.184-0.866-0.381-1.34-0.592c-0.421-0.209-0.854-0.406-1.3-0.59c-0.447-0.184-0.881-0.276-1.301-0.276
                            c-1.314,0-2.522,0.473-3.626,1.419c-1.104,0.945-1.655,2.785-1.655,5.517v14.817c0,0.842,0.342,1.498,1.025,1.971
                            c0.682,0.473,1.707,0.92,3.073,1.34c0.578,0.158,1.025,0.33,1.34,0.512c0.315,0.186,0.474,0.461,0.474,0.828
                            c0,0.631-0.5,0.945-1.498,0.945c-1.576,0-2.93-0.066-4.059-0.195c-1.131-0.131-2.378-0.197-3.744-0.197
                            c-0.895,0-1.683,0.027-2.364,0.078c-0.684,0.053-1.393,0.119-2.128,0.197C186.609,60.996,185.716,61.035,184.666,61.035z"/>
                    </g>
                </g>
                <g>
                    <path className="blinky" fill="#574847" d="M221.181,1.34h5.345V0c0,0-1.691,0-5.466,0c-2.482,0-3.276,1.051-3.532,1.773
                        C217.272,1.051,216.479,0,213.996,0c-3.774,0-5.466,0-5.466,0v1.34h5.345c0,0,1.772-0.135,2.665,2.094V65.74
                        c-0.893,2.229-2.665,2.092-2.665,2.092h-5.345v1.34c0,0,1.691,0,5.466,0c2.482,0,3.276-1.051,3.531-1.771
                        c0.256,0.721,1.05,1.771,3.532,1.771c3.774,0,5.466,0,5.466,0v-1.34h-5.345c0,0-1.771,0.135-2.664-2.09V3.432
                        C219.409,1.205,221.181,1.34,221.181,1.34z"/>
                </g>
            </g>
        </svg>
    )
}
