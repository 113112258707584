export const Avatar = ({ devName, width }) => {
    if (devName === "nick") {
        return (
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    width={width} viewBox="0 0 222 222" enableBackground="new 0 0 222 222">
                <g>
                    <circle fill="#FFFFFF" cx="111.19" cy="111.125" r="108.209"/>
                    <path fill="#F2F4F2" d="M73.34,156.616c-9.065-6.305-18.523-35.076-18.523-35.076c-5.134-0.172-7.882-11.43-11.43-23.647
                        s6.306-16.553,6.306-16.553s-3.941-59.118,60.694-59.118c64.635,0,59.907,59.512,59.907,59.512
                        c14.188,0.788,5.518,16.159,4.729,25.618c-0.787,9.459-10.246,15.765-10.246,15.765c-0.789,19.705-18.918,34.289-18.918,34.289
                        c-1.577,6.305,3.152,13.399,3.152,13.399c9.677-1.001,32.623,7.035,39.438,9.524C204.949,161.917,215,137.612,215,111
                        C215,53.654,168.345,7,111,7C53.654,7,7,53.654,7,111c0,27.045,10.38,51.708,27.361,70.225
                        c10.589-5.453,38.585-11.209,38.585-11.209C75.705,165.68,73.34,156.616,73.34,156.616z"/>
                    <path fill="#303551" d="M91.735,128.231c-0.345,0.52-0.618,1.07-0.821,1.652C91.26,129.351,91.515,128.795,91.735,128.231z"/>
                    <path fill="#303551" d="M155.392,49.317c-0.771-1.377-1.727-2.647-2.768-3.831c-2.744-3.118-6.092-5.696-9.584-7.92
                        c-1.842-1.173-3.747-2.249-5.701-3.224c-1.104-0.552-2.188-1.083-3.305-1.59c-0.756-0.344-1.581-0.528-2.395-0.72
                        c-1.32-0.312-2.59-0.734-3.875-1.087c-1.476-0.405-3.104-0.526-4.616-0.756c-2.708-0.412-5.427-0.766-8.154-1.016
                        c-5.317-0.488-10.687-0.563-15.965,0.337c-4.222,0.72-8.313,2.021-12.446,3.112c-12,3.167-27,18.5-29.667,37.5
                        s1.5,39.417,3.583,48.75c2.083,9.333,8.833,25.666,20,34.916s21.25,11.584,31.083,10.834c2.463-0.188,4.916-0.434,7.211-0.77
                        c0.343,0.087,1.262-0.07,2.959-0.51c2.025-0.403,3.838-0.905,5.326-1.527c9.287-3.113,23.492-11.061,32.781-39.88
                        C171.099,87.068,160.724,58.827,155.392,49.317z M136.664,132.887c-1.988-0.382-3.025-0.532-3.971-1.08
                        c-0.922-1.012-2.361-2.5-3.748-4.022c-2.887-3.165-6.246-9.466-13.898-11.124c-1.317,0.73-2.729,1.25-4.196,1.35l-0.067,0.004
                        l-0.067-0.004c-1.508-0.102-2.957-0.645-4.304-1.407c-8.192,1.218-11.173,5.09-13.146,8.061c-0.741,1.115-1.06,2.358-1.531,3.567
                        c3.326-5.01,12.98-7.098,23.416-5.961c8.445,0.918,10.971,2.836,12.626,4.975l0.447,0.164l0.107,0.553
                        c0.004,0.017,0.009,0.039,0.012,0.057c0.113,0.163,0.226,0.327,0.338,0.491c0.352,1.243,1.691,4.204,2.937,7.405
                        c1.81,4.653,2.309,6.627,1.3,11.094c-1.008,4.467-5.475,8.356-5.475,8.356c-0.07,0.089-0.143,0.175-0.215,0.263
                        c-0.429,0.316-0.891,0.676-1.395,1.078c-4.835,3.869-9.99-2.773-11.888-6.492c-1.897-3.72-0.813-7.361-0.232-8.872
                        s0.108-1.272-3.106-1.418c-3.215-0.146-3.554,0.146-3.28,2.401s0.227,8.272-2.629,11.531c-2.856,3.26-7.421,7.271-14.441-2.256
                        s-0.515-12.85,0.296-20.152c0.061-0.545,0.187-1.064,0.36-1.564c-1.168,1.799-3.387,3.32-8.93,3.918
                        c-10.694,1.154-22.739-7.312-23.993-35.639s7.698-31.044,7.698-31.044c-2.507-6.769,0.371-18.665,8.031-24.674
                        c7.602-5.963,15.784,0.858,20.907,4.979s3.273-1.693,3.273-1.693c5.477,5.461,13.689,17.132,13.689,17.132
                        c1.499-5.577,9.536-13.79,16.237-19.448c8.262-4.892,17.404-2.495,23.098,1.437c6.052,4.178,5.91,22.191,5.91,22.191
                        s6.916,3.458,6.916,22.477s-3.458,32.707-9.51,39.334C148.191,135.481,140.41,133.608,136.664,132.887z"/>
                    <path d="M97.131,55.79l1.541-1.275c-4.985-6.023-11.296-9.724-11.563-9.878l-1.004,1.729C86.167,46.402,92.371,50.039,97.131,55.79
                        z"/>
                    <path d="M98.659,52.27c1.215,2.087,4.494,7.99,5.071,10.215l1.936-0.502c-0.764-2.946-5.094-10.403-5.278-10.719L98.659,52.27z"/>
                    <path d="M133.212,44.175l-0.126-0.992l-0.114-0.994c-0.214,0.025-5.271,0.699-9.08,9.058l1.82,0.829
                        C129.009,44.84,133.171,44.181,133.212,44.175z"/>
                    <polygon points="111.718,66.595 108.221,59.632 106.434,60.53 112.012,71.638 116.091,60.423 114.21,59.739 	"/>
                    <path d="M110.841,123.664c-7.098,0-16.568,3.405-16.968,3.55l-0.531,0.193l-0.109,0.555c-0.55,2.796-2.341,10.537-4.003,12.745
                        c-0.822,1.093-0.896,2.804-0.212,4.947c1.274,3.999,5.161,9.239,8.701,10.366c0.34,0.107,0.7,0.163,1.071,0.163
                        c2.611-0.001,5.547-2.722,6.98-6.472c1.754-4.59,0.864-9.404-2.355-13.264h14.737c-3.22,3.86-4.11,8.674-2.354,13.266
                        c1.434,3.748,4.369,6.47,6.979,6.47c0.371,0,0.731-0.056,1.071-0.163c3.539-1.127,7.426-6.367,8.701-10.366
                        c0.684-2.144,0.609-3.854-0.213-4.947c-1.65-2.192-3.43-9.849-3.992-12.688c-0.003-0.018-0.008-0.04-0.012-0.057l-0.107-0.553
                        l-0.447-0.164l-0.081-0.029C127.3,127.07,117.941,123.664,110.841,123.664z M130.642,145.046c-1.224,3.839-4.82,8.246-7.401,9.067
                        c-1.481,0.472-4.202-1.521-5.576-5.115c-0.827-2.164-2.142-7.744,3.51-12.806l1.948-1.745H98.442l1.949,1.745
                        c5.651,5.06,4.337,10.641,3.51,12.805c-1.374,3.593-4.092,5.587-5.577,5.116c-2.581-0.821-6.178-5.229-7.402-9.067
                        c-0.458-1.436-0.494-2.608-0.096-3.137c1.997-2.653,3.788-10.76,4.253-12.999c2.084-0.711,9.933-3.247,15.761-3.247
                        c5.823,0,13.579,2.533,15.643,3.245c0.466,2.235,2.256,10.348,4.254,13.001C131.136,142.438,131.1,143.611,130.642,145.046z"/>
                    <path d="M98.218,130.135l-0.335,0.943c0.089,0.031,6.042,2.105,13.63,2.105c3.826,0,8.067-0.527,12.182-2.11l-0.359-0.934
                        C111.414,134.727,98.349,130.182,98.218,130.135z"/>
                    <path d="M69.937,83.616l1.654,2.502c0.079-0.052,7.958-5.196,14.281-3.731c6.999,1.625,17.873,3.268,17.981,3.284l0.446-2.967
                        c-0.108-0.016-10.866-1.642-17.749-3.239C78.99,77.708,70.304,83.374,69.937,83.616z"/>
                    <path d="M79.04,91.757l1.388,1.44c2.576-2.481,5.191-3.729,7.67-4.208c-0.414,0.592-0.66,1.308-0.66,2.083
                        c0,2.012,1.636,3.648,3.647,3.648c2.012,0,3.648-1.636,3.648-3.648c0-0.681-0.2-1.312-0.526-1.858
                        c4.357,1.1,7.451,3.795,7.69,4.008l1.334-1.49C103.106,91.62,90.582,80.64,79.04,91.757z"/>
                    <path d="M117.265,82.704l0.446,2.967c0.108-0.017,10.983-1.659,17.981-3.284c6.295-1.461,14.203,3.68,14.282,3.732l1.653-2.503
                        c-0.365-0.243-9.053-5.91-16.614-4.151C128.131,81.063,117.373,82.688,117.265,82.704z"/>
                    <path d="M119.023,91.732l1.334,1.49c0.239-0.213,3.333-2.907,7.689-4.006c-0.326,0.545-0.525,1.175-0.525,1.856
                        c0,2.012,1.637,3.648,3.648,3.648s3.647-1.636,3.647-3.648c0-0.774-0.246-1.49-0.659-2.081c2.479,0.478,5.094,1.726,7.67,4.206
                        l1.387-1.44C131.671,80.641,119.149,91.62,119.023,91.732z"/>
                    <path d="M129.664,125.879c1.672,2.748,2.991,4.918,5.357,4.918c0.533,0,1.342,0.129,2.278,0.277
                        c1.483,0.234,3.354,0.531,5.405,0.531c3.227,0,6.896-0.734,10.197-3.6c6.516-5.654,9.666-18.233,9.363-37.384
                        c0.131-1.242,1.371-15.221-8.351-21.978c0.481-3.052,2.011-15.645-4.155-22.618c-2.98-3.37-7.277-4.894-12.789-4.53l0.132,1.996
                        c4.855-0.325,8.607,0.977,11.155,3.856c6.253,7.065,3.607,21.437,3.58,21.581l-0.127,0.665l0.57,0.364
                        c9.51,6.081,8.006,20.36,7.99,20.503l-0.008,0.065l0.001,0.064c0.3,18.569-2.618,30.649-8.673,35.905
                        c-4.695,4.076-10.281,3.189-13.979,2.603c-1.021-0.162-1.902-0.302-2.592-0.302c-1.242,0-2.304-1.746-3.648-3.957
                        c-2.25-3.7-5.363-8.804-12.383-11.169c2.416-2.33,4.003-4.76,4.125-4.95l-1.684-1.08c-0.008,0.012-0.146,0.221-0.372,0.54
                        c-1.258,1.777-5.625,7.462-10.276,7.828c-4.651-0.366-9.019-6.051-10.276-7.828c-0.226-0.319-0.364-0.528-0.372-0.54l-1.684,1.08
                        c0.118,0.184,1.603,2.465,3.887,4.722c-7.551,2.244-10.816,7.567-13.144,11.396c-1.345,2.212-2.407,3.958-3.649,3.958
                        c-0.699,0-1.575,0.143-2.59,0.308c-3.484,0.565-8.748,1.421-13.162-2.396c-5.939-5.135-8.796-17.287-8.492-36.118l0.001-0.065
                        l-0.007-0.065c-0.017-0.144-1.54-14.41,7.989-20.502l0.571-0.365l-0.127-0.665c-0.028-0.144-2.681-14.5,3.577-21.577
                        c2.548-2.882,6.296-4.185,11.159-3.859l0.132-1.996c-5.513-0.357-9.81,1.161-12.789,4.53C65.641,53,67.17,65.592,67.651,68.645
                        c-9.722,6.756-8.482,20.736-8.351,21.977c-0.307,19.415,2.783,32.066,9.185,37.602c5.122,4.428,11.173,3.442,14.791,2.855
                        c0.929-0.15,1.732-0.281,2.269-0.281c2.366,0,3.687-2.17,5.358-4.918c2.395-3.938,5.51-9.055,13.2-10.852
                        c0.722,0.582,1.498,1.116,2.309,1.575c1.347,0.763,2.796,1.306,4.304,1.407l0.067,0.004l0.067-0.004
                        c1.468-0.1,2.879-0.619,4.196-1.35c0.753-0.417,1.473-0.901,2.15-1.43C124.342,117.175,127.348,122.071,129.664,125.879z"/>
                    <path d="M123.447,50.089l-1.676-1.092c-1.986,3.05-4.242,10.835-4.338,11.165l1.922,0.554
                        C119.377,60.638,121.623,52.891,123.447,50.089z"/>
                    <path d="M143.812,155.741c8.727-7.25,15.85-18.195,19.221-33.818c0.246-1.14,0.482-2.26,0.711-3.362
                        c4.254-0.118,5.788-1.735,6.679-6.856c0.278-1.596,1.058-3.936,1.884-6.414c1.797-5.392,3.834-11.502,2.899-15.554
                        c-0.419-1.813-1.294-2.962-2.599-3.412c-1.549-0.535-3.27,0.115-4.472,0.777c0.523-14.757-2.271-25.567-8.635-37.253
                        c-13.075-24.01-44.757-24.704-48.326-24.704c-0.271,0-0.424,0.003-0.425,0.004l-0.47,0c0,0-0.154-0.004-0.448-0.004
                        c-3.569,0-35.251,0.693-48.326,24.704c-6.595,12.111-8.842,23.197-8.059,37.281c-1.205-0.672-2.954-1.349-4.525-0.806
                        c-1.306,0.451-2.18,1.599-2.599,3.413c-0.935,4.051,1.102,10.161,2.899,15.552c0.826,2.478,1.606,4.819,1.884,6.415
                        c0.924,5.311,2.533,6.857,7.154,6.867c0.231,1.101,0.469,2.216,0.714,3.352c3.547,16.443,10.639,26.993,18.559,33.75
                        c0.382,1.572,2.542,11.826-2.154,25.33l1.889,0.656c3.83-11.009,3.302-19.849,2.669-24.069c11.879,8.868,24.966,9.95,30.566,9.95
                        c10.19,0,21.352-2.93,30.918-9.936c-0.633,4.226-1.153,13.059,2.672,24.055l1.889-0.656
                        C141.366,167.731,143.389,157.542,143.812,155.741z M171.957,88.215c0.609,0.211,1.047,0.875,1.3,1.971
                        c0.809,3.503-1.219,9.584-2.848,14.471c-0.888,2.662-1.653,4.96-1.957,6.704c-0.722,4.153-1.594,5.051-4.3,5.188
                        c2.12-10.502,3.419-19.294,3.868-26.999C168.775,88.981,170.724,87.79,171.957,88.215z M53.076,111.361
                        c-0.304-1.744-1.07-4.043-1.957-6.705c-1.629-4.886-3.656-10.967-2.847-14.47c0.253-1.096,0.69-1.759,1.3-1.971
                        c1.286-0.442,3.372,0.884,4.042,1.414c0.613,7.817,2.094,16.589,4.229,26.937C54.766,116.504,53.838,115.745,53.076,111.361z
                        M60.929,121.5c-7.236-33.546-8.511-50.783,2.333-70.696c12.523-22.996,43.122-23.66,46.569-23.66l0.422,0.004l0.92-0.004
                        c3.448,0,34.046,0.664,46.569,23.66c10.201,18.731,10.979,35.249,3.334,70.696c-7.004,32.471-30.718,44.039-50.575,44.039
                        C101.102,165.54,69.743,162.364,60.929,121.5z"/>
                    <path d="M76.856,186.417l-1.067,1.692c0.219,0.138,14.883,9.17,34.958,9.17c10.105,0,21.581-2.289,33.275-9.153l-1.013-1.725
                        C109.16,206.27,77.175,186.619,76.856,186.417z"/>
                    <path fill="#584947" d="M112.683,200.374c-39.987,0-45.54-20.733-45.54-20.733c-7.385,1.025-17.607,5.347-25.26,8.997
                        C60.271,205.024,84.491,215,111,215c27.527,0,52.587-10.755,71.209-28.276c-14.925-6.108-32.132-8.193-32.132-8.193
                        S152.669,200.374,112.683,200.374z"/>
                    <path d="M72.256,174.83c-0.979,0.068-18.452,1.409-34.38,10.046c0.51,0.506,1.024,1.008,1.545,1.503
                        c15.613-8.216,32.743-9.538,32.975-9.554L72.256,174.83z"/>
                    <path d="M152.742,174.83l-0.141,1.995c0.223,0.016,15.955,1.223,31.009,8.554c0.519-0.507,1.031-1.017,1.539-1.534
                        C169.783,176.129,153.68,174.895,152.742,174.83z"/>
                    <path fill="#303551" d="M111,0C49.794,0,0,49.794,0,111s49.794,111,111,111s111-49.794,111-111S172.206,0,111,0z M183.61,185.378
                        c-0.463,0.452-0.929,0.901-1.4,1.345C163.587,204.245,138.527,215,111,215c-26.509,0-50.729-9.976-69.117-26.362
                        c-0.832-0.741-1.655-1.491-2.462-2.259c-0.521-0.495-1.035-0.997-1.545-1.503c-1.201-1.188-2.373-2.405-3.515-3.65
                        C17.38,162.708,7,138.045,7,111C7,53.654,53.654,7,111,7c57.345,0,104,46.654,104,104c0,26.612-10.051,50.917-26.551,69.329
                        c-1.072,1.197-2.174,2.369-3.3,3.516C184.641,184.362,184.128,184.872,183.61,185.378z"/>
                    
                </g>
            </svg>
        )
    } else if (devName === "lane") {
        return (
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    width={width} viewBox="0 0 222 221.999" enableBackground="new 0 0 222 221.999">
                <g>
                    <circle fill="#FFFFFF" cx="111.19" cy="111.125" r="108.209"/>
                    <path fill="#F2F4F2" d="M157.152,26.25c0.018,2.501-0.93,4.924-1.064,7.431c-0.119,2.219-1.049,2.832-2.426,4.456
                        c0.904,1.299,2.424,2.12,3.514,3.272c1.115,1.18,1.99,2.625,2.859,3.988c2.027,3.179,4.395,6.582,5.918,10.001
                        c1.629,3.656,2.217,8.38,2.211,12.411c-0.002,1.992-0.209,4.118-0.014,6.099c0.135,1.357,0.229,2.715,0.277,4.137
                        c0.076,2.217,1.006,4.611,0.527,6.76c-0.521,2.341-1.316,4.491-1.33,6.954c-0.012,2.643,1.654,4.783,2.033,7.337
                        c0.383,2.575-0.199,5.199-0.723,7.671c-1.002,4.736-2.803,9.324-4.357,13.909c-0.592,1.745-1.078,3.604-1.852,5.264
                        c-1.057,2.268-2.119,3.377-4.15,4.891c-0.859,0.641-0.449,0.188-0.887,1.049c-0.215,0.425-0.295,1.068-0.445,1.529
                        c-0.467,1.428-0.74,2.945-1.285,4.379c-1.014,2.659-2.977,4.58-4.215,7.055c-1.311,2.617-2.629,5.011-4.379,7.369
                        c-1.049,1.416-2.441,3.162-2.27,5.041c0.162,1.771,0.539,3.842,0.996,5.598c0.162,0.621,2.039,6.741,2.92,6.65
                        c10.152-1.051,34.904,7.844,40.311,9.846C205.304,161.054,215,137.14,215,111c0-41.03-23.885-76.583-58.48-93.494
                        c0.5,1.296,0.725,2.75,0.773,3.975C157.357,23.074,157.142,24.655,157.152,26.25z"/>
                    <path fill="#F2F4F2" d="M33.928,180.14c6.057-3.261,13.043-5.031,19.645-6.811c6.077-1.639,12.596-3.825,18.897-4.273
                        c1.24-0.088,2.471-0.096,3.712-0.134c0.474-3.315,0.41-7.755-0.373-10.972c-0.906-3.725-3.347-6.523-5.319-9.789
                        c-3.579-5.923-5.813-11.336-5.795-18.305c-2.431-1.262-5.479-3.379-6.898-5.701c-1.267-2.072-1.877-3.99-2.818-6.2
                        c-2.216-5.2-3.552-10.401-3.552-16.086c0-2.052-0.475-4.541-0.104-6.535c0.447-2.403,2.551-4.287,2.354-6.899
                        c-0.24-3.182-1.68-6.126-2.291-9.224c-0.552-2.791-0.357-5.3-0.357-8.123c0-3.759,0.092-7.367,0.789-11.054
                        c0.967-5.11,4.14-9.904,7.244-14.025c3.236-4.296,6.747-8.379,10.622-12.113c1.266-1.22,2.299-2.943,3.823-3.826
                        c1.82-1.055,3.888-2.629,5.862-3.338c2.756-0.99,5.194-2.205,7.674-3.771c1.517-0.958,3.428-1.891,5.229-1.541
                        c-0.384-2.21-1.224-4.531-1.181-6.851c0.042-2.211,0.686-4.359,1.957-6.013C44.228,17.086,7,59.775,7,111
                        c0,22.479,7.171,43.314,19.343,60.342C30.65,177.138,33.928,180.14,33.928,180.14z"/>
                    <path d="M124.054,144.73c0.771-0.298,1.158-1.165,0.861-1.938c-0.299-0.773-1.166-1.158-1.939-0.861
                        c-11.557,4.447-24.295,0.031-24.423-0.014c-0.779-0.275-1.638,0.131-1.915,0.912c-0.277,0.779,0.13,1.637,0.909,1.914
                        c0.37,0.133,6.287,2.193,13.89,2.193C115.369,146.937,119.753,146.386,124.054,144.73z"/>
                    <path d="M88.085,92.375c6.13,1.629,15.349,3.223,15.737,3.29l0.51-2.956c-0.095-0.017-9.478-1.639-15.477-3.233
                        c-6.772-1.801-14.462,3.949-14.786,4.195l1.81,2.393C75.947,96.012,82.742,90.956,88.085,92.375z"/>
                    <path d="M103.231,101.732c-0.127-0.113-12.649-11.093-24.191,0.024l1.387,1.44c2.576-2.481,5.192-3.729,7.671-4.208
                        c-0.413,0.591-0.66,1.308-0.66,2.083c0,2.012,1.637,3.648,3.647,3.648s3.647-1.636,3.647-3.648c0-0.681-0.199-1.312-0.526-1.858
                        c4.357,1.1,7.452,3.795,7.69,4.008L103.231,101.732z"/>
                    <path d="M143.214,101.757c-11.543-11.116-24.064-0.137-24.191-0.024l1.336,1.49c0.238-0.213,3.332-2.907,7.688-4.006
                        c-0.326,0.546-0.525,1.176-0.525,1.856c0,2.012,1.637,3.648,3.648,3.648s3.646-1.636,3.646-3.648c0-0.774-0.246-1.49-0.658-2.082
                        c2.479,0.478,5.094,1.726,7.67,4.207L143.214,101.757z"/>
                    <path d="M76.856,182.417l-1.066,1.691c0.219,0.139,14.883,9.17,34.957,9.17c10.104,0,21.581-2.289,33.274-9.152l-1.012-1.725
                        C109.159,202.27,77.176,182.618,76.856,182.417z"/>
                    <path fill="#5A95B2" d="M112.458,196.523c-39.987,0-45.316-18.884-45.316-18.884c-7.858,1.092-18.932,5.915-26.699,9.691
                        c18.554,17.162,43.35,27.668,70.557,27.668c28.248,0,53.896-11.324,72.658-29.667c-15.236-6.579-33.58-8.802-33.58-8.802
                        S152.445,196.523,112.458,196.523z"/>
                    <path d="M72.257,172.829c-1.006,0.07-19.399,1.485-35.655,10.76c0.494,0.506,0.992,1.008,1.497,1.504
                        c15.952-8.855,34.059-10.252,34.297-10.268L72.257,172.829z"/>
                    <path d="M152.603,174.825c0.229,0.016,16.904,1.301,32.346,9.225c0.5-0.508,1-1.018,1.49-1.536
                        c-15.74-8.31-32.732-9.616-33.697-9.685L152.603,174.825z"/>
                    <path d="M110.716,130.772l0.067,0.006l0.067-0.006c5.721-0.385,10.538-7.801,10.741-8.117l-1.684-1.079
                        c-0.045,0.068-4.436,6.819-9.125,7.196c-4.689-0.377-9.081-7.128-9.125-7.196l-1.684,1.079
                        C100.177,122.972,104.995,130.388,110.716,130.772z"/>
                    <path d="M95.808,23.897c-6.454,2.206-13.043,5.584-18.779,9.784c-12.792,9.364-20.319,21.708-21.197,34.758
                        c-0.687,10.219,0.521,19.879,2.308,27.989c-0.534,0.596-0.929,1.409-1.169,2.448c-0.935,4.052,1.103,10.162,2.899,15.554
                        c0.826,2.477,1.606,4.817,1.884,6.414c0.871,5.01,2.353,6.67,6.4,6.85c2.362,10.548,6.043,19.046,10.392,25.742l-0.066,0.02
                        c0.03,0.107,2.985,10.927-2.1,25.546l1.889,0.657c3.418-9.828,3.364-17.925,2.863-22.553c9.447,12.413,21.157,17.434,29.292,17.434
                        l-0.001-0.994l0.081,0.994c7.319,0,19.653-4.043,29.752-16.674c-0.525,4.585-0.641,12.795,2.838,22.793l1.889-0.657
                        c-5.07-14.577-2.129-25.439-2.1-25.548l-0.08-0.023c4.588-6.715,8.525-15.467,11.047-26.729c4.299-0.105,5.846-1.714,6.738-6.857
                        c0.277-1.597,1.059-3.938,1.885-6.414c1.797-5.392,3.834-11.502,2.898-15.554c-0.33-1.435-0.951-2.445-1.84-3.031
                        c1.717-7.991,2.854-17.438,2.184-27.419c-0.84-12.478-7.508-23.115-16.461-31.13c2.348-2.168,4.084-4.79,5.004-7.787
                        c1.377-4.49,0.777-9.243-1.375-13.693c-1.492-0.659-3.002-1.285-4.529-1.875c3.26,4.647,4.518,9.857,3.037,14.688
                        c-0.795,2.588-2.336,4.849-4.439,6.711c-11.164-9.076-25.182-14.294-35.957-14.294c-3.778,0-7.999,0.689-12.35,1.948
                        c-1.545-3.56-1.924-7.282-0.845-10.803c0.52-1.694,1.369-3.244,2.474-4.641c-1.32,0.136-2.631,0.303-3.937,0.488
                        c-0.577,1.035-1.054,2.126-1.405,3.273C93.669,15.432,94.07,19.772,95.808,23.897 M110.422,172.54
                        c-12.285,0-33.208-12.26-40.493-46.039c-0.095-0.438-0.19-0.859-0.285-1.291l-0.985-6.705c-3.306-22.483-0.815-29.713,0.963-32.021
                        c0.606-0.787,1.089-0.927,1.203-0.95l2.218,0.114l-0.821-2.151c-2.103-5.506-1.643-11.286,1.231-15.459
                        c2.717-3.946,7.357-6.119,13.067-6.119c2.698,0,5.637,0.475,8.732,1.411c5.49,1.66,11.476,2.502,17.792,2.502
                        c12.164,0,21.645-3.062,24.262-4c0.684-0.246,1.375-0.37,2.057-0.37c3.059,0,5.561,2.416,7.121,4.442
                        c3.797,4.934,5.924,12.59,4.738,17.066l-0.41,1.549l1.43,0.279c0.477,0.332,5.164,4.425,0.646,33.684l-1.467,9.506l0.58-1.166
                        c-7.354,33.531-28.82,45.719-41.503,45.719l-0.076,0.993V172.54 M162.72,68.627c1.012,15.048-2.332,29.103-5.363,38.347
                        c1.572-17.215-1.002-22.669-2.996-24.374c0.854-5.378-1.43-13.239-5.5-18.527c-2.826-3.672-6.111-5.612-9.498-5.612
                        c-1.027,0-2.061,0.184-3.07,0.545c-2.5,0.897-11.568,3.825-23.248,3.825c-6.022,0-11.716-0.798-16.923-2.373
                        c-3.379-1.022-6.609-1.54-9.602-1.54c-6.727,0-12.245,2.634-15.538,7.417c-3.177,4.614-3.915,10.792-2.063,16.782
                        c-0.444,0.266-0.926,0.651-1.407,1.209c-2.989,3.46-4.042,11.41-3.128,23.248c-3.082-9.229-6.592-23.563-5.558-38.934
                        c1.859-27.663,34.834-44.594,52.169-44.594c9.523,0,22.07,4.728,31.966,12.044C154.884,44.906,161.902,56.461,162.72,68.627
                        M63.724,120.501c-0.304-1.743-1.07-4.042-1.957-6.703c-1.594-4.78-3.561-10.697-2.89-14.232
                        c2.881,11.535,6.679,19.275,6.954,19.827l0.815,1.635c0.353,1.51,0.703,3.062,1.051,4.642
                        C65.243,125.476,64.416,124.484,63.724,120.501 M163.421,99.327c0.809,3.503-1.219,9.584-2.848,14.471
                        c-0.887,2.661-1.652,4.96-1.957,6.703c-0.723,4.16-1.596,5.056-4.314,5.188c0.48-2.192,0.965-4.321,1.447-6.381
                        c0.455-0.926,4.41-9.126,7.25-21.113C163.169,98.502,163.316,98.867,163.421,99.327"/>
                    <path fill="#303551" d="M111,0C49.794,0,0,49.794,0,111c0,61.206,49.794,110.999,111,110.999c61.207,0,111-49.793,111-110.999
                        C222,49.794,172.207,0,111,0z M183.658,185.332c-18.762,18.343-44.41,29.667-72.658,29.667c-27.207,0-52.003-10.506-70.557-27.668
                        c-0.793-0.734-1.575-1.48-2.345-2.238c-0.505-0.496-1.003-0.998-1.497-1.504c-3.72-3.812-7.154-7.904-10.259-12.247
                        C14.171,154.314,7,133.479,7,111C7,59.775,44.228,17.086,93.047,8.555c1.091-0.19,2.188-0.36,3.29-0.517
                        c1.306-0.185,2.616-0.352,3.937-0.488c1.463-0.15,2.937-0.264,4.416-0.354c-5.939,5.808-3.604,13.842-3.604,13.842
                        c23.509-7.958,45.505,10.106,45.505,10.106s2.613-7.332,0.539-14.97c-0.307-1.132-1.09-2.316-2.221-3.494
                        c1.156,0.4,2.305,0.821,3.443,1.261c1.527,0.59,3.037,1.215,4.529,1.875c1.225,0.541,2.438,1.103,3.637,1.689
                        C191.115,34.417,215,69.97,215,111c0,26.14-9.695,50.054-25.678,68.345c-0.939,1.076-1.9,2.133-2.883,3.169
                        c-0.49,0.519-0.99,1.028-1.49,1.536C184.521,184.481,184.091,184.908,183.658,185.332z"/>
                    <path d="M133.482,92.375c5.336-1.423,12.137,3.636,12.205,3.688l1.809-2.393c-0.324-0.246-8.027-5.992-14.785-4.195
                        c-6,1.594-15.383,3.217-15.477,3.233l0.51,2.956C118.132,95.599,127.351,94.004,133.482,92.375z"/>
                    <path fill="#303551" d="M158.5,99.122c0,0,4.791-22.103,2.086-34.313s-16.617-29.831-32.459-35.241
                        c-15.844-5.41-23.109-4.869-36.015,0.772S67.382,45.334,61.585,59.245s0.694,37.326,0.694,37.326s0.618-11.979,3.4-13.911
                        c0,0-3.323-18.085,10.124-24.035s17.234-1.544,25.581,1.083s20.171,2.319,28.208-0.463s17.699-3.633,21.795,4.327
                        s5.328,13.884,4.098,17.621C155.486,81.193,160.585,89.461,158.5,99.122z"/>
                    <path fill="#ABA8B5" d="M103.572,161.183c-2.972,0-6.284-1.136-8.324-3.945c-4.769-6.566-0.313-15.166,1.251-16.572
                        c0.782-0.704,2.64-0.88,5.171-0.88s5.735,0.176,9.214,0.176l0.11,0.032c3.478,0,6.683-0.176,9.214-0.176s4.389,0.176,5.17,0.879
                        c1.564,1.406,6.02,10.007,1.252,16.573c-2.041,2.809-5.354,3.944-8.324,3.944c-3.975,0-7.335-2.031-6.217-4.805
                        c1.953-4.847,1.953-7.583-1.251-7.583l0.202-0.032c-3.205,0-3.205,2.736-1.251,7.584
                        C110.907,159.151,107.546,161.183,103.572,161.183 M119.416,132.404c-1.701,0-3.285,0.095-4.559,0.13
                        c-3.863,0.109-3.863,0.109-3.863,0.109s0,0-3.863-0.109c-1.272-0.035-2.857-0.13-4.558-0.13c-5.205,0-11.499,0.881-13.267,7.009
                        c-1.663,5.768-6.418,7.326-9.452,7.326c-1.243,0-2.197-0.262-2.531-0.603c0,0,3.776,8.6,9.561,13.368
                        c5.753,4.742,15.528,11.916,23.974,11.916c0.046,0,0.091,0,0.137,0s0.092,0,0.138,0c8.444,0,18.219-7.174,23.973-11.916
                        c5.783-4.769,9.561-13.368,9.561-13.368c-0.334,0.341-1.289,0.603-2.531,0.603c-3.035,0-7.789-1.559-9.453-7.326
                        C130.914,133.286,124.621,132.404,119.416,132.404"/>
                </g>
            </svg>
        )
    } else if (devName === "darryl") {
        return (
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    width={width} viewBox="0 0 222 221.999" enableBackground="new 0 0 222 221.999">
                <g>
                    <circle fill="#FFFFFF" cx="111.19" cy="111.125" r="108.209"/>
                    <path fill="#F2F4F2" d="M47.602,178.476c8.245-2.464,16.862-4.92,25.341-6.296c1.264-1.993,1.478-4.312,0.656-6.48
                        c-0.707-1.863-0.926-2.9,0.026-4.758c-4.195-3.154-8.342-6.176-11.19-10.701c-1.31-2.082-2.453-5.003-3.06-7.42
                        c-0.502-2.005-1.008-4.011-1.454-6.029c-0.394-1.782-0.504-2.914-2.174-3.998c-1.026-0.666-2.039-1.184-2.928-2.033
                        c-1.973-1.887-3.459-5.039-4.55-7.656c-1.017-2.44-1.371-5.486-2.004-8.036c-0.392-1.574-0.459-3.172-0.605-4.791
                        c-0.157-1.739-0.824-3.806-2.029-5.128c-1.588-1.74-3.973-2.58-5.496-4.591c-2.581-3.407-4.955-7.154-3.642-11.677
                        c0.393-1.353,1.049-2.221,1.835-3.241c1.012-1.313,0.796-3.081,1.08-4.7c0.594-3.391,0.858-6.854,1.819-10.173
                        c0.416-1.434,1.156-2.773,1.345-4.257c0.204-1.603,0.271-3.229,0.512-4.833c0.224-1.495,0.861-2.848,1.089-4.353
                        c0.398-2.628,0.833-5.212,1.381-7.795c0.582-2.743,0.853-5.575,1.618-8.284c0.664-2.353,2.335-4.554,3.771-6.533
                        c1.289-1.778,2.436-3.681,4.175-5.044c1.872-1.466,3.689-2.843,5.65-4.246c3.316-2.372,7.075-3.652,11.204-3.493
                        c1.018,0.04,2.081,0.252,3.111,0.12c0.977-0.125,1.332-0.454,2.262-0.941c2.137-1.122,4.244-2.364,6.276-3.77
                        c2.055-1.421,3.83-2.301,6.281-2.514c3.033-0.265,6.344-0.662,9.31-1.336c2.746-0.623,6.099-0.693,8.691-1.7
                        c2.21-0.857,4.164-1.543,6.634-1.299c3.627,0.358,7.372,0.064,11.013,0.491c2.724,0.318,5.421,0.727,8.13,1.168
                        c3.928,0.64,8.318,1.157,12.023,2.653c2.807,1.132,5.594,2.109,8.088,3.852c2.008,1.403,4.15,2.589,6.107,4.091
                        c4.091,3.139,5.886,8.027,8.539,12.188c0.988,1.548,1.532,3.305,2.783,4.695c0.646,0.721,1.48,1.351,2.322,1.825
                        c0.438,0.247,2.758,0.723,2.898,0.952c0.414,0.677,1.225,1.637,1.104,2.488c-0.164,1.151-1.762,1.641-2.186,2.557
                        c-0.282,0.609-0.068,1.331,0.043,1.991c0.277,1.652,0.96,3.248,1.444,4.858c1.319,4.385,3.901,8.367,5.751,12.556
                        c2.3,5.205,4.702,9.994,2.865,15.781c-0.727,2.289-3.041,4.459-2.184,7.004c1.021,3.029,5.227,0.17,7.054-0.251
                        c1.685-0.389,3.233,0.905,3.706,2.531c0.58,1.996-0.498,4.309-1.621,5.871c-3.051,4.246-8.316,6.672-12.537,9.599
                        c-3.524,2.443-3.314,5.441-3.305,9.449c0.01,3.7-1.623,6.665-3.199,9.81c-1.197,2.389-3.668,6.46-6.8,6.488
                        c-0.169,0.789-0.959,1.379-1.221,2.229c-0.409,1.328-0.71,2.678-1.184,3.906c-1.278,3.319-2.822,6.688-3.97,10.123
                        c-1.223,3.667-2.604,6.237-5.507,8.82c-1.276,1.137-2.604,2.15-4.156,2.992c-1.296,0.703-2.304,0.998-2.645,2.583
                        c-0.3,1.392,0.255,2.86,0.717,4.179c0.157,0.448,1.798,5.045,2.406,4.982c9.085-0.94,29.858,6.084,38.005,9.007
                        c17.348-18.59,27.983-43.521,27.983-70.894c0-40.451-23.217-75.58-57.022-92.769c-17.436-7.777-35.113-10.875-47.196-10.875
                        c-16.366,0-42.396,6.288-64.379,22.199C22.418,48.689,7.006,78.11,7.006,111.066c0,27.812,10.974,53.107,28.818,71.787
                        c1.638-0.727,3.792-1.74,4.124-1.862C42.468,180.063,45.029,179.245,47.602,178.476"/>
                    <path fill="#303551" d="M90.92,135.95c0.346-0.532,0.601-1.089,0.821-1.652C91.396,134.816,91.123,135.369,90.92,135.95z"/>
                    <path d="M122.982,141.273c-11.559,4.447-24.296,0.03-24.422-0.013c-0.782-0.276-1.638,0.131-1.915,0.911
                        c-0.277,0.78,0.129,1.637,0.909,1.915c0.37,0.132,6.287,2.193,13.889,2.193c3.933,0,8.316-0.552,12.617-2.206
                        c0.773-0.298,1.158-1.166,0.861-1.939C124.623,141.362,123.755,140.979,122.982,141.273"/>
                    <path d="M88.758,89.456c5.109,1.187,13.047,2.385,13.127,2.397l0.298-1.979c-0.079-0.012-7.941-1.199-12.973-2.366
                        c-5.488-1.281-11.796,2.843-12.062,3.019l1.103,1.669C78.309,92.157,84.116,88.384,88.758,89.456z"/>
                    <path d="M133.036,89.456c4.644-1.073,10.448,2.701,10.507,2.74l1.103-1.669c-0.267-0.176-6.58-4.299-12.062-3.019
                        c-5.031,1.167-12.894,2.354-12.973,2.366l0.298,1.979C119.988,91.841,127.927,90.643,133.036,89.456z"/>
                    <path d="M78.809,99.823l1.388,1.441c2.576-2.48,5.191-3.729,7.67-4.207c-0.413,0.591-0.66,1.307-0.66,2.081
                        c0,2.012,1.636,3.648,3.647,3.648c2.012,0,3.648-1.637,3.648-3.648c0-0.681-0.199-1.311-0.525-1.856
                        c4.356,1.1,7.45,3.794,7.689,4.007L103,99.8C102.875,99.687,90.353,88.708,78.809,99.823z"/>
                    <path d="M127.816,97.281c-0.326,0.546-0.525,1.177-0.525,1.857c0,2.012,1.637,3.648,3.648,3.648s3.647-1.637,3.647-3.648
                        c0-0.774-0.247-1.49-0.66-2.082c2.479,0.479,5.095,1.727,7.671,4.208l1.387-1.441c-11.543-11.115-24.066-0.137-24.191-0.023
                        l1.334,1.489C120.366,101.076,123.46,98.381,127.816,97.281z"/>
                    <path d="M143.772,161.847c8.233-6.821,15.038-16.934,18.626-31.145c4.525-0.038,6.116-1.603,7.031-6.863
                        c0.277-1.598,1.058-3.938,1.884-6.417c1.239-3.717,2.617-7.881,3.012-11.429c3.599-0.699,7.206-2.425,10.039-4.926
                        c2.681-2.364,4.155-5.007,4.159-7.449l-0.002-0.11l-1.949,0.064h-0.049v0.002l0,0v0.003c-0.012,0.103-0.268,1.667-4.535,2.187
                        c-1.438,0.175-3.309,0.166-4.053-0.876c-0.762-1.063-0.414-3.238,0.979-6.122c2.883-5.97-1.679-31.996-9.462-42.456
                        c0.796-0.372,1.245-0.752,1.298-0.797l2.184-1.912l-2.897,0.161c-1.661,0.068-3.853-3.55-5.979-7.07
                        c-1.697-2.808-3.62-5.99-5.928-8.64c-7.117-8.173-21.094-13.057-41.545-14.517C93.09,11.854,77.931,24.488,77.297,25.027
                        l1.294,1.524c0.149-0.127,15.219-12.633,37.85-11.022c24.793,1.771,35.533,8.501,40.179,13.836
                        c2.194,2.519,4.069,5.623,5.725,8.361c1.889,3.124,3.506,5.801,5.25,7.134c-1.338,0.339-3.376,0.376-6.007-0.988l-0.922,1.775
                        c2.042,1.059,3.819,1.416,5.29,1.416c0.529,0,1.003-0.057,1.451-0.134c7.56,9.32,12.409,35.367,9.706,40.967
                        c-1.775,3.676-2.046,6.42-0.805,8.155c1.057,1.477,3.05,2.05,5.922,1.696c1.254-0.152,2.258-0.397,3.063-0.695
                        c-0.57,0.85-1.328,1.701-2.252,2.516c-3.2,2.824-8.766,5.363-13.998,4.658c-7.683-1.03-8.574-4.36-8.582-4.394l-1.959,0.4
                        c0.029,0.154,0.676,3.101,5.697,4.904c0.65,2.883,0.219,7.157-0.412,10.811c-0.629-4.532-2.129-10.305-5.645-14.736
                        c-3.919-4.941-3.45-8.003-2.801-12.241c0.368-2.408,0.786-5.137,0.477-8.645c-0.773-8.769-3.005-14.242-3.489-15.346
                        c7.808-7.443,4.575-15.314,4.54-15.396l-1.838,0.787c0.03,0.072,2.995,7.286-5.078,14.058c-5.424,4.551-10.262,1.684-18.277-3.067
                        c-3.861-2.287-8.236-4.881-13.655-7.229c-9.521-4.124-13.355-3.47-17.064-2.838c-2.861,0.488-5.563,0.95-11.2-1.129
                        c-11.996-4.422-9.86-15.301-9.763-15.762l-0.978-0.208l-0.978-0.21c-0.014,0.063-0.638,3.186,0.324,6.945
                        c-2.97,2.183-17.57,13.645-16.389,27.23c0.546,6.284,2.219,11.176,3.694,15.492c1.881,5.501,3.24,9.476,1.178,13.383
                        c-0.861,1.632-1.561,2.852-2.122,3.762c0.035-1.166-0.059-2.597-0.39-4.317c-0.474-2.469-1.861-2.488-2.435-2.383
                        c-2.812,0.521-5.773,8.149-4.952,12.754c0.307,1.718,0.483,3.35,0.582,4.788c-1.113-2.209-2.778-4.715-5.206-6.77
                        c-2.449-2.072-5.466-3.303-7.891-4.292c-1.381-0.563-3.272-1.335-3.464-1.711c0-0.001,0.038-0.141,0.342-0.425
                        c0.458-0.428,1.855-1.731,1.33-3.161c-0.549-1.495-2.775-1.675-3.216-1.695l-0.095,1.998c0.583,0.027,1.313,0.236,1.424,0.34
                        c0,0.003-0.025,0.326-0.809,1.057c-0.763,0.713-1.081,1.452-0.944,2.196c0.268,1.454,2.021,2.17,4.676,3.253
                        c1.431,0.584,3.081,1.258,4.656,2.135c-0.459,3.864,1.291,8.734,2.854,13.073c0.843,2.341,1.64,4.553,1.926,6.198
                        c0.878,5.051,2.38,6.695,6.5,6.853c3.721,14.93,10.472,24.702,17.963,31.08c0.342,0.947,2.483,7.861-2.19,21.299l1.889,0.656
                        c3.464-9.959,3.354-16.506,2.821-19.956c11.838,8.764,24.838,9.837,30.414,9.837c10.137,0,21.234-2.901,30.768-9.828
                        c-0.531,3.452-0.639,9.996,2.822,19.947l1.889-0.656C141.409,169.902,143.369,163.011,143.772,161.847 M168.777,106.209
                        c0.671,0.09,1.357,0.134,2.055,0.134c0.48,0,0.967-0.034,1.455-0.075c-0.442,3.234-1.723,7.078-2.871,10.521
                        c-0.887,2.662-1.654,4.962-1.957,6.707c-0.748,4.298-1.65,5.113-4.587,5.201c0.054-0.238,0.115-0.467,0.167-0.707
                        c0.135-0.626,0.266-1.243,0.396-1.856l0.734-2.628c0.139-0.497,3.08-11.143,2.182-17.747
                        C167.095,105.933,167.886,106.09,168.777,106.209 M55.083,123.496c-0.316-1.818-1.142-4.109-2.015-6.534
                        c-1.349-3.743-2.842-7.902-2.811-11.167c0.265,0.193,0.524,0.394,0.774,0.604c2.4,2.03,3.983,4.603,4.995,6.764
                        c0.773,4.341,1.74,9.193,2.955,14.827c0.05,0.23,0.104,0.453,0.155,0.681C56.618,128.492,55.783,127.522,55.083,123.496
                        M60.935,127.568c-0.59-2.736-1.12-5.283-1.599-7.681l0.216-2.491c0.018-0.201,0.416-4.995-0.637-10.895
                        c-0.729-4.082,1.969-9.753,3.222-10.389c0.061,0.112,0.153,0.335,0.232,0.746c0.936,4.866-0.274,6.981-0.278,6.989l-0.497,0.781
                        l0.741,0.555c0.254,0.191,0.593,0.256,0.934,0.182c0.489-0.11,1.636-0.367,5.345-7.395c2.465-4.671,0.913-9.213-1.054-14.964
                        c-1.438-4.207-3.069-8.977-3.595-15.019c-1.019-11.718,11.427-22.238,15.059-25.043c1.376,3.397,4.237,6.958,10.042,9.098
                        c6.136,2.262,9.233,1.733,12.228,1.224c3.42-0.585,6.958-1.188,15.933,2.702c5.302,2.297,9.62,4.855,13.431,7.113
                        c5.23,3.1,9.531,5.648,13.658,5.648c2.15,0,4.254-0.705,6.417-2.373c0.708,1.794,2.446,6.802,3.095,14.144
                        c0.289,3.268-0.11,5.87-0.462,8.166c-0.671,4.38-1.25,8.162,3.211,13.786c5.132,6.471,5.627,16.436,5.646,19.674
                        c-0.354,1.747-0.732,3.563-1.137,5.44c-7.004,32.47-30.717,44.038-50.574,44.038C101.108,171.606,69.749,168.431,60.935,127.568"/>
                    <path d="M76.863,187.484l-1.067,1.691c0.219,0.138,14.883,9.17,34.958,9.17c10.105,0,21.581-2.289,33.275-9.153l-1.014-1.725
                        C109.166,207.339,77.182,187.685,76.863,187.484z"/>
                    <path fill="#969696" d="M112.689,201.441c-39.986,0-45.54-20.734-45.54-20.734c-7.151,0.993-16.963,5.076-24.525,8.648
                        c18.295,16.001,42.225,25.711,68.382,25.711c27.159,0,51.917-10.47,70.46-27.579c-14.746-5.874-31.382-7.891-31.382-7.891
                        S152.676,201.441,112.689,201.441z"/>
                    <path d="M72.263,175.896c-0.966,0.068-17.97,1.376-33.717,9.694c0.518,0.504,1.042,1.003,1.57,1.495
                        c15.424-7.896,32.058-9.178,32.286-9.194L72.263,175.896z"/>
                    <path d="M152.609,177.893c0.219,0.015,15.48,1.186,30.322,8.222c0.525-0.504,1.047-1.012,1.563-1.526
                        c-15.163-7.422-30.82-8.626-31.746-8.691L152.609,177.893z"/>
                    <path fill="#303551" d="M111.006,0.066c-61.206,0-111,49.794-111,111s49.794,111,111,111c61.206,0,111-49.794,111-111
                        S172.212,0.066,111.006,0.066z M184.494,184.588c-0.516,0.515-1.037,1.022-1.563,1.526c-0.484,0.464-0.974,0.919-1.465,1.373
                        c-18.543,17.109-43.301,27.579-70.46,27.579c-26.157,0-50.087-9.71-68.382-25.711c-0.849-0.742-1.684-1.5-2.508-2.27
                        c-0.528-0.492-1.052-0.991-1.57-1.495c-0.922-0.897-1.833-1.807-2.722-2.737c-17.844-18.68-28.818-43.976-28.818-71.787
                        c0-32.956,15.412-62.377,39.402-81.444C64.164,15.51,86.616,7.066,111.006,7.066c16.894,0,32.858,4.053,46.978,11.231
                        c33.806,17.188,57.022,52.317,57.022,92.769c0,27.372-10.636,52.304-27.983,70.894
                        C186.193,182.849,185.353,183.729,184.494,184.588z"/>
                    <path d="M119.947,123.252c-0.116,0.199-0.348,0.475-1.01,0.569c-1.092,0.157-1.809,0.136-2.384,0.115
                        c-1.431-0.047-2.165,0.117-3.105,1.866c-0.806,1.495-2.377,1.427-2.434,1.429l-0.073-0.004l-0.063,0.003
                        c-0.064-0.001-1.637,0.068-2.443-1.428c-0.941-1.749-1.674-1.914-3.105-1.866c-0.576,0.021-1.292,0.042-2.383-0.115
                        c-0.663-0.095-0.894-0.37-1.01-0.569c-0.509-0.87-0.034-2.589,0.172-3.12l-0.931-0.365l-0.931-0.365
                        c-0.118,0.301-1.124,2.995-0.041,4.855c0.346,0.594,1.053,1.344,2.457,1.545c1.265,0.18,2.11,0.153,2.729,0.134
                        c0.829-0.026,0.829-0.025,1.282,0.814c1.243,2.309,3.515,2.48,4.165,2.48h0.203c0.65,0,2.923-0.172,4.165-2.48
                        c0.453-0.84,0.455-0.842,1.281-0.814c0.619,0.021,1.465,0.047,2.73-0.134c1.404-0.201,2.111-0.951,2.457-1.545
                        c1.083-1.86,0.076-4.555-0.041-4.855l-0.934,0.365l-0.93,0.363C119.98,120.664,120.457,122.382,119.947,123.252z"/>
                    <path d="M42.354,88.045c1.07-0.298,2.553-0.845,3.076-2.157c0.626-1.57-0.429-3.33-1.652-5.367
                        c-0.333-0.555-0.595-0.892-0.768-1.115l-0.121-0.154c0.017-0.177,0.111-0.595,0.218-1.07c0.348-1.542,1.072-4.752,2.054-11.81
                        c1.977-14.197,5.665-40.59,31.058-39.167l0.114-1.996C49.09,23.633,45.246,51.26,43.181,66.096
                        c-0.971,6.975-1.683,10.129-2.025,11.645c-0.358,1.591-0.444,1.969,0.274,2.893c0.161,0.207,0.361,0.466,0.632,0.917
                        c0.829,1.381,1.769,2.947,1.509,3.597c-0.14,0.351-0.763,0.695-1.754,0.971c-4.434,1.233-4.484,6.574-4.484,6.801h2
                        C39.333,92.878,39.368,88.875,42.354,88.045z"/>
                    <path fill="#BCB4B2" d="M99.394,158.137c-0.7,0-1.478-0.19-2.368-0.636c-6.171-3.086-3.692-9.835-2.025-14.168
                        s5.667-2.667,11-3.667c3.762-0.705,4.536-1.905,4.667-2.553c-0.055-0.271,0-0.447,0-0.447s0.055,0.177,0,0.447
                        c0.131,0.647,0.905,1.848,4.667,2.553c5.334,1,9.334-0.666,11,3.667c1.667,4.333,4.146,11.082-2.025,14.168
                        c-0.889,0.445-1.664,0.636-2.357,0.636c-4.117,0-5.398-6.707-11.19-6.707S103.55,158.137,99.394,158.137 M60.333,118
                        c0,0,1.012,16.042,7.282,26.384s18.73,26.061,43.078,26.061l0.325,0.027c24.348,0,36.809-15.719,43.078-26.061
                        s7.282-26.384,7.282-26.384s-9.333,26.334-15,32.334c-2.714,2.874-6.651,4.677-9.872,4.677c-3.502,0-6.156-2.133-5.461-7.344
                        c1.333-10-6.334-13.999-10.667-14.333s-4.148-1.654-9.523-1.654v-0.027c-5.375,0-5.19,1.32-9.523,1.654s-12,4.333-10.667,14.333
                        c0.694,5.21-1.959,7.344-5.461,7.344c-3.22,0-7.157-1.803-9.872-4.677C69.667,144.334,60.333,118,60.333,118"/>
                    <path fill="#584947" d="M160.408,42.4c2.936,1.468,2.33,0.193,2.33,0.193s-0.672-2.685-4.475-7.384
                        c-3.805-4.699-5.595-13.427-33.117-16.335c-27.521-2.908-31.549,2.686-43.185,7.832c0,0-2.909,7.608,0,13.649
                        s10.069,10.517,18.572,9.397S115.749,51.097,120,53.11c4.252,2.014,21.258,14.097,26.404,10.517s7.832-9.398,6.266-13.649
                        c0,0,2.049-3.081,4.16-1.521c2.109,1.56,3.67,1.284,6.146,1.284c2.478,0,1.835-0.643-1.651-1.927S157.471,40.933,160.408,42.4z"/>
                    <path fill="#584947" d="M174.72,69.191c-1.467-13.854-6.605-17.8-7.34-19.084c-0.733-1.284-0.917-2.11-1.284,0.092
                        s-7.248,0.825-7.248,0.825c3.12,8.257-5.138,14.589-5.138,14.589s1.101,2.293,2.568,7.798c1.469,5.505,0.459,15.414,1.285,18.992
                        c0.825,3.578,1.468,8.074,10.643,9.909c8.834,1.767,13.402-3.256,13.73-3.634c-0.126,0.091-0.763,0.312-4.006,0.238
                        c-4.037-0.092-4.953-4.495-3.486-8.624C175.914,86.164,176.189,83.045,174.72,69.191z"/>
                    <path fill="#584947" d="M181.968,98.643l-0.031,0.036L181.968,98.643z"/>
                    <path fill="#584947" d="M161.142,104.79c1.468,2.202,2.478,6.881,2.478,6.881c0-1.743-0.551-5.964-0.551-5.964L161.142,104.79z"/>
                    <path fill="#584947" d="M48.477,56.802c-2.183,8.099-5.138,21.653-2.019,24.59s-0.917,7.89-3.395,9.816s1.652,4.771,0.367,6.423
                        s1.101,2.294,5.321,3.303s6.973,6.331,6.973,6.331s-0.551-0.918-0.275-3.396s0.917-8.624,5.597-10.551s4.771,3.67,4.771,3.67
                        c1.468-5.78-1.376-13.12-2.936-17.065s-4.771-7.981-1.376-20.552s15.23-19.357,15.23-19.357
                        c-1.376-5.872,1.652-12.019,1.652-12.019C55.358,28.823,51.963,43.865,48.477,56.802z"/>
                </g>
            </svg>
        )
    } else if (devName === "chris") {
        return (
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    width={width} viewBox="0 0 222 222" enableBackground="new 0 0 222 222">
                <g>
                    <circle fill="#FFFFFF" cx="111.19" cy="111.125" r="108.209"/>
                    <path fill="#F2F4F2" d="M53.572,173.865c6.077-1.638,12.596-3.824,18.897-4.273c1.24-0.088,2.471-0.096,3.712-0.133
                        c0.406-2.843,0.531-5.899-0.105-8.7c-0.637-2.803-0.207-5.797-1.188-8.569c-0.839-2.374-3.219-4.439-4.624-6.575
                        c-1.62-2.461-3.437-5.071-4.275-7.897c-0.352-1.184-0.67-2.416-0.91-3.608c-0.343-1.702,0.309-4.278-2.145-4.726
                        c-1.463-0.268-2.024,0.78-3.519-0.179c-2.318-1.487-3.744-5.007-4.873-7.405c-1.901-4.041-3.278-8.278-4.479-12.535
                        c-1.271-4.499-1.849-9.377-0.405-13.854c0.877-2.723,2.799-7.679,6.417-7.187c0.944-3.495,0.031-7.482-1.139-10.804
                        c-1.163-3.301-0.338-6.604-0.929-9.931c-0.616-3.473,0.384-6.337,1.84-9.558c1.347-2.977,2.354-6.141,3.419-9.185
                        c0.698-1.995,1.462-3.834,2.32-5.771c0.299-0.674,1.254-1.91,1.344-2.603c0.093-0.706-0.354-2.059-0.368-2.872
                        c-0.021-1.202,0.007-2.407,0-3.61c-0.021-3.892,3.916-9.737,7.016-12.114c3.766-2.888,8.302-4.169,12.623-5.876
                        c8.062-3.186,16.707-3.485,25.176-4.847c5.466-0.879,11.533-0.114,16.63,2c2.732,1.134,5.852,3.501,7.996,5.547
                        c1.764,1.683,2.764,4.449,4.674,5.954c1.723,1.357,4.916,1.067,6.939,1.539c1.859,0.434,3.541,0.947,5.188,1.824
                        c2.174,1.159,4.727,2.171,6.408,4.024c2.771,3.055,4.879,7.609,5.594,11.714c0.502,2.882-0.135,6.221-0.064,9.188
                        c0.086,3.71,0.285,7.42,0.736,11.104c0.66,5.377,0.066,10.772,1.049,16.086c0.242,1.312,0.529,3.263,0.283,4.574
                        c-0.252,1.344-1.311,2.238-1.146,3.735c1.303-0.435,2.715-1.775,4.242-1.298c1.379,0.432,3.307,2.589,3.85,3.949
                        c0.9,2.26,0.721,4.789,0.49,7.086c-0.527,5.255-1.25,9.736-3.297,14.678c-1.967,4.748-2.488,9.733-5.479,14.015
                        c-1.08,1.548-2.525,2.741-3.668,4.046c-1.469,1.676-1.563,3.153-2.266,5.301c-1.027,3.138-2.758,5.761-4.436,8.617
                        c-1.203,2.053-1.992,4.067-3.256,6.006c-0.668,1.026-1.461,2.011-1.99,3.174c-0.6,1.32-0.354,1.402-0.121,2.542
                        c0.273,1.33,0.588,13.857,3.277,13.577c9.939-1.028,33.877,7.478,39.953,9.714C205.158,161.407,215,137.333,215,111
                        C215,53.654,168.345,7,111,7C53.654,7,7,53.654,7,111c0,26.564,10.017,50.831,26.465,69.232c0.298,0.293,0.463,0.444,0.463,0.444
                        C39.984,177.416,46.971,175.645,53.572,173.865z"/>
                    <path d="M154.744,125.272c4.867,0,6.557-1.612,7.525-7.186c0.293-1.685,1.115-4.15,1.984-6.761
                        c1.891-5.668,4.031-12.093,3.051-16.344c-0.436-1.891-1.346-3.087-2.701-3.555c-2.115-0.729-4.547,0.664-5.658,1.427
                        c1.949-15.253,0.674-35.039-2.029-39.89c-1.436-2.576-2.498-4.803-3.16-6.273l1.16,0.313l0.338-0.811
                        c0.145-0.346,1.309-3.576-2.002-10.54c-3.166-6.663-16.594-8.386-19.348-8.672c-1.518-2.132-8.891-11.802-17.01-11.045l0.092,0.996
                        l0.094,0.996c7.756-0.747,15.361,10.448,15.438,10.562l0.27,0.399l0.479,0.039c4.221,0.352,15.715,2.397,18.18,7.583
                        c1.992,4.19,2.182,6.806,2.1,8.049l-3.176-0.858l0.723,1.848c0.057,0.146,1.436,3.646,4.076,8.387
                        c2.168,3.891,3.598,21.926,2.014,36.771c-0.779-3.144-2.016-6.803-3.873-9.119c-1.982-2.471-1.818-5.533-1.645-8.775
                        c0.113-2.108,0.23-4.287-0.26-6.369c-0.234-0.987-0.299-2.247-0.367-3.58c-0.27-5.307-0.641-12.574-11.775-15.098
                        c-9.707-2.2-38.833-6.123-45.13,4.345c-2.689,4.468-5.365,6.754-7.278,7.915c1.342-2.123,2.94-4.96,3.134-6.689l0.248-2.224
                        l-1.822,1.298c-0.028,0.02-1.918,1.366-3.878,2.787c1.335-3.316,2.8-6.344,3.514-6.818l5.653-3.762l-6.497,1.972
                        c-0.294,0.089-7.246,2.245-11.293,7.521c-2.262,2.95-2.719,4.348-3.123,5.58c-0.3,0.918-0.538,1.644-1.575,3.015
                        c-1.705,2.254-1.896,2.732-2.361,4.205c-0.074,0.236-0.158,0.503-0.263,0.817c-0.765,2.291-0.547,4.463-0.336,6.563
                        c0.271,2.696,0.506,5.025-1.318,7.299c-1.88,2.345-3.124,6.064-3.902,9.233c-1.972-16.223-1.537-38.326,0.609-42.179
                        c2.597-4.662,5.49-9.391,5.52-9.438l-0.853-0.522l-0.853-0.522c-0.029,0.048-2.944,4.81-5.563,9.51
                        c-2.581,4.632-2.781,28.919-0.628,45.046c-1.187-0.779-3.464-1.977-5.454-1.289c-1.356,0.468-2.266,1.664-2.702,3.555
                        c-0.98,4.251,1.161,10.676,3.051,16.344c0.87,2.61,1.692,5.076,1.985,6.76c0.95,5.465,2.6,7.117,7.251,7.181
                        c4.646,16.778,6.923,21.295,14.764,26.05c2.616,1.586,5.093,4.245,7.715,7.06c1.916,2.058,3.926,4.204,6.157,6.138
                        c-2.073-3.331-3.63-6.33-3.63-6.33c0.016,0.006,0.031,0.011,0.047,0.017c-0.374-0.397-0.744-0.795-1.11-1.188
                        c-2.72-2.919-5.288-5.676-8.142-7.407c-7.828-4.746-9.715-9.412-15.005-29.016c-0.393-7.986-0.911-15.093-1.368-21.365
                        c-0.137-1.875-0.268-3.677-0.391-5.426c0.637-3.274,1.902-8.268,4.062-10.958c2.335-2.912,2.037-5.88,1.748-8.751
                        c-0.198-1.978-0.387-3.845,0.242-5.729c0.109-0.326,0.197-0.604,0.274-0.85c0.389-1.234,0.481-1.527,2.049-3.599
                        c1.238-1.639,1.565-2.636,1.881-3.6c0.371-1.134,0.754-2.306,2.81-4.985c2.061-2.688,5.057-4.51,7.283-5.591
                        c-1.426,2.737-2.8,6.457-3.326,7.936l-1.111,3.12l2.652-1.984c1.109-0.83,2.619-1.926,3.911-2.857
                        c-1.099,2.114-2.733,4.556-3.523,5.659l-1.305,1.823l2.229-0.247c0.24-0.026,5.948-0.759,11.535-10.043
                        c4.754-7.903,28.455-6.716,42.976-3.425c9.656,2.188,9.955,8.064,10.221,13.248c0.072,1.427,0.141,2.774,0.416,3.938
                        c0.424,1.802,0.32,3.745,0.209,5.803c-0.186,3.475-0.379,7.067,2.082,10.135c2.152,2.683,3.418,7.653,4.055,10.928
                        c-0.207,2.862-0.445,5.873-0.699,9.063c-0.418,5.284-0.893,11.27-1.309,17.751c-5.293,19.609-7.178,24.276-15.008,29.023
                        c-2.854,1.731-5.422,4.488-8.141,7.407c-0.406,0.437-0.818,0.877-1.234,1.319c0.137-0.05,0.275-0.097,0.41-0.148
                        c0,0-1.418,2.732-3.35,5.878c2.025-1.813,3.871-3.789,5.637-5.686c2.623-2.814,5.1-5.474,7.715-7.06
                        c7.928-4.809,10.166-9.364,14.92-26.609V125.272z M159.619,94.839c0.68-0.549,2.932-1.998,4.332-1.521
                        c0.66,0.229,1.135,0.939,1.404,2.113c0.855,3.703-1.281,10.112-2.998,15.262c-0.934,2.8-1.74,5.219-2.059,7.051
                        c-0.816,4.695-1.814,5.472-5.16,5.523c0.199-0.734,0.402-1.482,0.613-2.261l0.031-0.197c0.41-6.39,0.877-12.299,1.291-17.532
                        l0.041,0.341l1.191-6.58c0.188-1.034,0.355-2.103,0.508-3.196L159.619,94.839z M60.116,117.743c-0.319-1.831-1.125-4.25-2.059-7.05
                        c-1.717-5.149-3.854-11.559-2.999-15.262c0.271-1.173,0.743-1.884,1.403-2.112c1.39-0.479,3.653,0.972,4.335,1.521l0.65-0.808
                        c0.152,1.044,0.315,2.05,0.488,3.006l1.192,6.58l0.006-0.049c0.373,5.218,0.759,10.948,1.066,17.226l0.033,0.212
                        c0.209,0.774,0.411,1.519,0.61,2.249C61.842,123.139,60.896,122.231,60.116,117.743z"/>
                    <path d="M124.755,143.166c0.771-0.297,1.158-1.165,0.859-1.938c-0.297-0.773-1.166-1.156-1.938-0.861
                        c-12.197,4.696-25.629,0.034-25.765-0.013c-0.778-0.276-1.638,0.132-1.915,0.911c-0.277,0.78,0.13,1.637,0.909,1.915
                        c0.389,0.139,6.605,2.305,14.595,2.305C115.632,145.484,120.238,144.905,124.755,143.166z"/>
                    <path d="M86.939,88.05c6.449,1.715,16.15,3.392,16.56,3.463l0.51-2.957c-0.1-0.017-9.979-1.725-16.299-3.404
                        c-7.083-1.884-15.156,4.146-15.496,4.402l1.811,2.393C74.096,91.892,81.249,86.537,86.939,88.05z"/>
                    <path d="M101.494,99.506l1.335-1.489c-0.133-0.119-13.277-11.643-25.395,0.023l1.387,1.441c2.757-2.655,5.556-3.972,8.204-4.462
                        c-0.472,0.634-0.761,1.412-0.761,2.262c0,2.104,1.711,3.814,3.813,3.814s3.813-1.711,3.813-3.814c0-0.748-0.225-1.441-0.598-2.031
                        C97.938,96.397,101.24,99.279,101.494,99.506z"/>
                    <path d="M119.523,98.017l1.336,1.489c0.254-0.226,3.555-3.104,8.197-4.252c-0.373,0.589-0.596,1.281-0.596,2.027
                        c0,2.104,1.711,3.814,3.814,3.814c2.102,0,3.811-1.711,3.811-3.814c0-0.849-0.287-1.625-0.758-2.26
                        c2.648,0.49,5.447,1.806,8.203,4.46l1.387-1.441C132.798,86.373,119.656,97.896,119.523,98.017z"/>
                    <path d="M80.402,152.911l-1.924,0.545c0.03,0.107,2.985,10.928-2.1,25.547l1.889,0.656
                        C83.558,164.451,80.534,153.375,80.402,152.911z"/>
                    <path d="M142.882,154.455l-1.924-0.544c-0.133,0.464-3.156,11.54,2.135,26.748l1.889-0.656
                        C139.912,165.426,142.853,154.563,142.882,154.455z"/>
                    <path d="M76.856,182.418l-1.066,1.691c0.219,0.138,14.883,9.17,34.957,9.17c10.104,0,21.581-2.289,33.274-9.153l-1.012-1.725
                        C109.159,202.271,77.176,182.617,76.856,182.418z"/>
                    <path fill="#303551" d="M112.458,196.523c-39.987,0-45.316-18.883-45.316-18.883c-7.858,1.092-18.931,5.914-26.699,9.69
                        C58.997,204.493,83.793,215,111,215c28.248,0,53.896-11.325,72.658-29.668c-15.236-6.578-33.58-8.802-33.58-8.802
                        S152.445,196.523,112.458,196.523z"/>
                    <path d="M72.257,172.83c-1.006,0.071-19.398,1.485-35.655,10.76c0.494,0.506,0.992,1.008,1.496,1.503
                        c15.953-8.855,34.06-10.251,34.298-10.268L72.257,172.83z"/>
                    <path d="M152.603,174.826c0.229,0.016,16.902,1.294,32.348,9.223c0.5-0.508,0.998-1.018,1.488-1.535
                        c-15.74-8.309-32.732-9.615-33.697-9.684L152.603,174.826z"/>
                    <path fill="#303551" d="M111,0C49.794,0,0,49.794,0,111s49.794,111,111,111c61.207,0,111-49.794,111-111S172.207,0,111,0z
                        M183.658,185.332C164.896,203.675,139.248,215,111,215c-27.207,0-52.002-10.507-70.556-27.669
                        c-0.793-0.734-1.575-1.479-2.346-2.238c-0.504-0.495-1.002-0.997-1.496-1.503c-1.069-1.097-2.115-2.216-3.137-3.357
                        C17.017,161.831,7,137.564,7,111C7,53.654,53.654,7,111,7c57.346,0,104,46.654,104,104c0,26.333-9.842,50.407-26.035,68.749
                        c-0.826,0.937-1.666,1.859-2.525,2.765c-0.49,0.518-0.988,1.027-1.488,1.535C184.523,184.48,184.091,184.908,183.658,185.332z"/>
                    <path d="M134.687,88.05c5.66-1.496,12.846,3.843,12.916,3.897l1.811-2.394c-0.34-0.257-8.412-6.286-15.498-4.402
                        c-6.318,1.679-16.199,3.388-16.299,3.404l0.51,2.957C118.537,91.441,128.238,89.765,134.687,88.05z"/>
                    <path d="M101.876,123.145c-0.911-1.984,2.006-4.45,2.035-4.475l-1.263-1.551c-0.171,0.139-4.162,3.437-2.589,6.861
                        c1.548,3.365,3.607,3.173,5.415,2.539c0.86-0.301,2.146,0.245,3.065,0.858c0.138,0.092,0.253,0.185,0.362,0.271
                        c0.442,0.352,0.993,0.79,2.13,0.79c1.139,0,1.689-0.438,2.131-0.789c0.109-0.087,0.225-0.18,0.363-0.272
                        c0.92-0.613,2.207-1.163,3.066-0.857c1.811,0.632,3.867,0.822,5.414-2.54c1.574-3.425-2.418-6.723-2.588-6.861l-1.264,1.551
                        c0.029,0.024,2.947,2.49,2.035,4.475c-0.996,2.166-1.768,1.895-2.936,1.488c-1.832-0.644-3.816,0.401-4.836,1.081
                        c-0.191,0.126-0.35,0.252-0.5,0.37c-0.352,0.28-0.447,0.355-0.887,0.355c-0.439,0-0.534-0.075-0.886-0.355
                        c-0.148-0.118-0.308-0.244-0.497-0.37c-1.021-0.68-3.003-1.721-4.836-1.082C103.643,125.039,102.871,125.31,101.876,123.145z"/>
                    
                        <line fill="none" stroke="#000000" strokeWidth="0" strokeLinecap="round" strokeLinejoin="round" x1="118.189" y1="20.69" x2="118.189" y2="20.69"/>
                    <path d="M77.777,24.84c6.816-3.947,23.399-6.729,30.813-5.924c6.931,0.752,9.541,1.751,9.599,1.774l0.004,0.001l0.371-0.928
                        l0.377-0.927c-0.072-0.028-1.217-0.479-3.957-1.009c-1.531-0.296-3.553-0.615-6.178-0.9c-7.104-0.767-24.275,1.691-32.03,6.182
                        c-7.377,4.271-10.072,8.027-10.184,8.185l1.633,1.154c0.008-0.011,0.265-0.364,0.824-0.966
                        C70.283,30.153,72.995,27.609,77.777,24.84z"/>
                    <polygon points="71.328,30.942 64.909,36.928 66.272,38.391 72.764,32.338 71.439,30.916 71.4,30.875 	"/>
                    <path fill="#303551" d="M110.127,138.202h0.033c0,0,4.462,1.369,9.914,1.369c5.453,0,6.463-1.615,5.453-1.817
                        c-0.576-0.115-1.129-0.56-1.609-0.876c-1.367-0.902-2.605-1.974-4.033-2.726c-1.449-0.766-2.842-1.589-4.275-2.396
                        c-0.646-0.365-1.303-0.666-1.984-0.96c-0.941-0.405-2.426-0.813-3.481-0.732c-1.056-0.081-2.54,0.327-3.48,0.732
                        c-0.682,0.294-1.338,0.595-1.984,0.96c-1.434,0.808-2.827,1.631-4.276,2.396c-1.427,0.752-2.666,1.823-4.033,2.726
                        c-0.479,0.316-1.032,0.761-1.609,0.876c-1.01,0.202,0.001,1.817,5.453,1.817S110.127,138.202,110.127,138.202z"/>
                    <path fill="#303551" d="M129.822,158.184c-0.135,0.052-0.273,0.099-0.41,0.148c-8.447,3.033-19.289,1.01-16.84-4.648
                        c2.49-5.75,2.477-5.663-2.459-5.663c-4.888,0-4.949-0.087-2.46,5.663c2.484,5.739-8.709,7.742-17.203,4.517
                        c-0.016-0.006-0.031-0.011-0.047-0.017c0,0,1.557,2.999,3.63,6.33c1.038,1.669,2.202,3.418,3.37,4.92c3.5,4.5,8.5,5,12.5,5h0.42
                        c4,0,9-0.5,12.5-5c1.273-1.638,2.547-3.572,3.65-5.372C128.404,160.916,129.822,158.184,129.822,158.184z"/>
                    <path fill="#303551" d="M73.24,37.323c0,0-7.342,6.993-9.331,18.329s-0.306,29.826-0.306,29.826
                        c0.008-0.182,0.022-0.362,0.043-0.541c0.34-2.984,2.336-5.6,2.985-7.97c0.689-2.512-0.826-10.604,1.928-13.533
                        s1.79-3.488,3.441-6.837c1.652-3.349,9.087-10.045,12.527-11.161c3.441-1.116,11.151-2.93,11.151-2.93s1.239,1.953-2.203,3.488
                        c-3.441,1.535-6.194,5.859-6.194,5.859l3.717-1.953l-0.688,6c0,0,1.239-1.535,3.305-4.604c2.064-3.069,5.782-5.441,11.563-7.395
                        c5.784-1.953,37.17,1.813,43.918,7.115c6.746,5.302,4.266,17.301,3.58,22.045c-0.689,4.744,3.715,10.883,3.715,10.883
                        c1.652-20.09-2.891-31.394-4.955-35.021s-4.129-7.674-4.129-7.674l3.854,0.419c0,0-0.137-3.906-2.891-6.697
                        c-1.605-1.627-4.602-1.973-6.721-2.311c-1.391-0.222-2.805-0.276-4.189-0.501c-1.428-0.232-2.582-0.787-3.824-1.526
                        c-0.785-0.466-1.561-0.928-2.348-1.378c-1.52-0.868-2.586-2.946-3.586-4.403c-2.203-3.209-7.953-5.353-7.953-5.353
                        s7.557,5.957-8.412,1.771c-15.97-4.186-33.657,4.903-36.208,7.674c-0.517,0.562-0.831,1.005-1.019,1.362
                        c-0.693,1.326,0.468,1.41,0.468,1.41s-2.615,2.675-4.13,4.35c-0.787,0.869,0.023,1.173,0.982,1.265
                        C72.222,37.418,73.24,37.323,73.24,37.323z"/>
                </g>
            </svg>
        )
    } else if (devName === "keith") {
        return (
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px"
                    width={width} viewBox="0 0 221.999 221.999" enableBackground="new 0 0 221.999 221.999">
                <g>
                    <circle fill="#FFFFFF" cx="111.19" cy="111.125" r="108.209"/>
                    <path fill="#F2F4F2" d="M194.358,173.12c3.805-5.094,7.15-10.548,9.984-16.298c1.02-2.166,1.979-4.36,2.861-6.585
                        c3.166-7.975,5.428-16.289,6.781-24.751c0.664-4.736,1.014-9.57,1.014-14.486c0-57.346-46.654-104-104-104C53.654,7,7,53.654,7,111
                        c0,24.768,8.708,47.535,23.215,65.417c1.115-0.792,2.178-1.339,3.432-1.883c3.338-1.447,6.284-3.042,9.908-3.715
                        c4.402-0.818,8.907-1.449,13.213-2.629c3.876-1.063,7.316-2.748,11.47-2.813c-0.022-2.891,0.557-5.744,1.178-8.555
                        c0.651-2.94,1.454-4.707-1.086-6.958c-2.927-2.594-4.953-5.892-6.902-9.249c-1.021-1.76-2.006-3.505-2.535-5.509
                        c-0.512-1.94-0.801-4.051-1.638-5.872c-2.272,0.263-5.131-2.253-6.79-3.554c-1.651-1.295-2.514-3.462-3.434-5.319
                        c-1.612-3.254-2.431-6.81-3.5-10.26c-0.94-3.033-2.681-6.128-3.127-9.277c-0.176-1.242-0.068-2.499,0.203-3.718
                        c0.508-2.289,1.721-3.409,3.22-5.055c1.435-1.575,2.918-2.586,4.859-3.48c0.908-0.419,0.808-0.189,0.816-1.462
                        c0.009-1.362,0.24-2.794,0.077-4.12c-0.206-1.681-0.952-3.244-1.024-4.955c-0.038-0.898,0.043-1.82,0.093-2.717
                        c0.143-2.556,0.434-5.103,0.86-7.626c1.178-6.971,3.412-13.804,6.907-19.964c4.393-7.743,10.79-14.164,18.568-18.509
                        c10.405-5.812,22.515-7.781,34.31-7.781c6.908,0,13.851,0.67,20.55,2.404c10.215,2.645,19.652,7.934,26.498,16.055
                        c7.072,8.389,10.586,19.062,13.447,29.462c0.908,3.303,0.734,6.171,0.633,9.532c-0.051,1.641,0.455,3.244,0.768,4.842
                        c0.123,0.63,1.234,5.063,0.748,5.398c1.666-1.146,4.117-0.072,5.682,0.75c1.877,0.987,2.475,2.667,2.869,4.721
                        c0.553,2.887,0.73,6.253,0.211,9.172c-0.584,3.292-2.154,6.213-4.027,8.937c-0.98,1.428-0.848,3.178-1.305,4.814
                        c-0.494,1.774-1.018,3.684-1.939,5.28c-0.729,1.265-1.582,2.726-2.584,3.813c-1.348,1.461-4.143,3.278-6.211,3.207
                        c-0.109,2.083-0.695,3.783-1.441,5.698c-0.459,1.174-0.619,2.427-1.227,3.541c-1.799,3.297-5.18,5.535-7.398,8.53
                        c-1.037,1.4-3.188,3.905-2.979,5.546c0.555,4.34,0.15,8.619,0.568,12.948c1.865,0.09,3.721-0.087,5.574,0.263
                        c2.77,0.522,5.574,1.344,8.301,1.88c4.578,0.9,8.971,2.021,13.406,3.324c2.223,0.652,4.365,1.48,6.525,2.292
                        c1.945,0.73,4.08,1.379,5.758,2.648c0.006-0.008,0.014-0.016,0.02-0.024C191.677,176.212,193.003,174.657,194.358,173.12z"/>
                    <path d="M122.976,140.451c-11.556,4.447-24.295,0.03-24.422-0.014c-0.782-0.276-1.638,0.131-1.915,0.911s0.129,1.637,0.909,1.915
                        c0.37,0.132,6.287,2.193,13.89,2.193c3.933,0,8.316-0.552,12.617-2.207c0.773-0.297,1.158-1.165,0.861-1.938
                        S123.749,140.153,122.976,140.451z"/>
                    <path d="M87.799,89.64c5.897,1.624,15.062,3.266,15.154,3.282l0.352-1.969c-0.091-0.017-9.167-1.643-14.974-3.241
                        c-6.337-1.745-13.602,3.877-13.909,4.117l1.234,1.574C75.723,93.352,82.429,88.167,87.799,89.64z"/>
                    <path d="M99.897,100.973l1.334-1.489c-0.125-0.113-12.647-11.092-24.191,0.023l1.388,1.441c2.576-2.48,5.191-3.729,7.67-4.208
                        c-0.414,0.592-0.66,1.308-0.66,2.082c0,2.011,1.636,3.647,3.647,3.647c2.012,0,3.648-1.637,3.648-3.647
                        c0-0.681-0.2-1.312-0.526-1.857C96.564,98.064,99.658,100.759,99.897,100.973z"/>
                    <path d="M133.235,87.712c-5.809,1.599-14.883,3.225-14.975,3.241l0.352,1.969c0.092-0.017,9.256-1.658,15.154-3.282
                        c5.359-1.477,12.076,3.711,12.145,3.764l1.232-1.574C146.837,91.589,139.567,85.969,133.235,87.712z"/>
                    <path d="M121.022,99.483l1.336,1.489c0.238-0.213,3.332-2.907,7.688-4.007c-0.326,0.546-0.525,1.176-0.525,1.856
                        c0,2.011,1.637,3.647,3.648,3.647s3.648-1.637,3.648-3.647c0-0.774-0.246-1.49-0.66-2.081c2.479,0.478,5.094,1.727,7.67,4.207
                        l1.387-1.441C133.671,88.393,121.149,99.37,121.022,99.483z"/>
                    <path d="M149.882,148.954l-0.229-0.064c4.934-5.609,10.27-13.33,13.352-22.851l0.029-0.104c2.496-12.058,4.098-22.044,4.783-30.72
                        l0.934,1.271c0.791-0.58,3.416-2.106,5.041-1.525c0.734,0.264,1.234,0.957,1.529,2.122c0.939,3.704-1.422,10.146-3.32,15.323
                        c-1.035,2.824-1.928,5.263-2.283,7.114c-0.943,4.934-2.113,5.592-6.264,5.592v2c5.313,0,7.158-1.618,8.229-7.217
                        c0.324-1.69,1.234-4.173,2.197-6.801c2.096-5.719,4.471-12.2,3.381-16.504c-0.467-1.833-1.406-3.015-2.793-3.513
                        c-2.551-0.915-5.617,0.923-6.609,1.593c1.35-18.007-1.322-30.342-8.35-43.79c-13.072-25.014-44.764-25.735-48.334-25.735
                        c-0.271,0-0.424,0.004-0.426,0.004h-0.469c0,0-0.154-0.004-0.449-0.004c-3.57,0-35.261,0.722-48.333,25.735
                        c-7.276,13.924-9.257,26.546-7.711,43.501c-1.281-0.785-3.918-2.104-6.156-1.304c-1.388,0.498-2.327,1.68-2.792,3.513
                        c-1.092,4.304,1.284,10.786,3.38,16.506c0.963,2.627,1.873,5.108,2.196,6.799c1.071,5.599,2.917,7.217,8.228,7.217v-2
                        c-4.149,0-5.32-0.658-6.263-5.592c-0.354-1.851-1.248-4.289-2.283-7.112c-1.898-5.178-4.26-11.621-3.32-15.325
                        c0.295-1.164,0.794-1.857,1.527-2.121c1.626-0.582,4.191,0.903,5.043,1.525l0.569-0.776c0.873,8.688,2.63,18.515,5.056,30.224
                        L59,126.039c3.056,9.439,8.328,17.107,13.226,22.704l-0.748,0.212c0.031,0.107,2.985,10.929-2.099,25.548l1.889,0.656
                        c4.004-11.512,3.246-20.652,2.583-24.618c4.312,4.626,8.086,7.544,9.417,8.521c1.24,0.909,2.523,2.179,3.882,3.522
                        c4.632,4.584,10.976,10.86,23.685,10.86c12.785,0,19.302-6.325,24.062-10.944c1.35-1.31,2.623-2.547,3.84-3.438
                        c1.252-0.918,4.662-3.552,8.646-7.706c-0.613,4.318-1.043,13.02,2.709,23.804l1.889-0.656
                        C146.911,159.926,149.853,149.062,149.882,148.954z M137.556,157.448c-1.328,0.973-2.65,2.257-4.053,3.617
                        c-4.764,4.624-10.693,10.379-22.667,10.379c-11.887,0-17.649-5.701-22.279-10.281c-1.413-1.398-2.747-2.718-4.106-3.715
                        c-1.767-1.295-17.429-13.189-23.531-31.974c-7.234-34.937-8.503-52.899,2.351-73.668c12.526-23.969,43.114-24.661,46.561-24.661
                        c0.253,0,0.389,0.003,0.423,0.004h0.524c0.008-0.001,0.145-0.004,0.396-0.004c3.446,0,34.036,0.692,46.561,24.661
                        c10.205,19.527,10.99,36.746,3.352,73.669C154.983,144.259,139.321,156.153,137.556,157.448z"/>
                    <path d="M76.959,180.491l-1.272,1.543c0.224,0.185,15.185,12.234,35.319,12.234c10.139,0,21.592-3.058,33.131-12.223l-1.244-1.566
                        C109.722,206.826,77.283,180.757,76.959,180.491z"/>
                    <path fill="#5A95B2" d="M207.753,200.046c-0.037,0.04-0.074,0.08-0.111,0.12l0.143-0.051
                        C207.774,200.092,207.763,200.069,207.753,200.046z"/>
                    <path fill="#F05423" d="M113.276,197.96c-42.124,2.058-48.032-24.139-48.032-24.139c-8.068,1.237-19.346,6.59-27.498,10.929
                        c18.812,18.686,44.706,30.249,73.253,30.249c30.064,0,57.185-12.828,76.191-33.293c-14.373-6.877-30.396-9.262-30.396-9.262
                        S154.544,195.944,113.276,197.96z"/>
                    <path d="M69.25,167.83c-0.237,0.019-19.36,1.632-35.712,12.483c0.45,0.502,0.905,0.999,1.364,1.492
                        c15.821-10.395,34.272-11.963,34.502-11.981L69.25,167.83z"/>
                    <path d="M152.603,169.825c0.244,0.017,19.242,1.469,35.471,10.927c0.465-0.514,0.926-1.033,1.381-1.557
                        c-16.506-9.828-35.686-11.294-36.713-11.366L152.603,169.825z"/>
                    <path fill="#303551" d="M111,221.999c61.207,0,111-49.794,111-110.999c0-61.206-49.793-111-111-111C49.794,0,0,49.794,0,111
                        C0,172.205,49.794,221.999,111,221.999z M191.739,176.472c-0.006,0.009-0.014,0.017-0.02,0.024
                        c-0.742,0.912-1.494,1.813-2.266,2.699c-0.455,0.523-0.916,1.043-1.381,1.557c-0.291,0.321-0.588,0.637-0.883,0.954
                        c-19.006,20.465-46.127,33.293-76.191,33.293c-28.547,0-54.441-11.563-73.253-30.249c-0.968-0.962-1.915-1.945-2.845-2.944
                        c-0.459-0.493-0.915-0.99-1.364-1.492c-1.139-1.271-2.246-2.57-3.322-3.896C15.708,158.535,7,135.768,7,111
                        C7,53.654,53.654,7,111,7c57.345,0,104,46.654,104,104c0,4.916-0.35,9.75-1.014,14.486c-1.553,11.099-4.863,21.641-9.643,31.336
                        c-2.834,5.75-6.18,11.204-9.984,16.298C193.509,174.257,192.634,175.372,191.739,176.472z"/>
                    <path d="M103.334,128.51c1.447-0.504,3.436,0.707,4.011,1.091c0.177,0.118,0.324,0.236,0.464,0.348
                        c0.546,0.435,1.166,0.928,2.493,0.928c1.328,0,1.945-0.492,2.492-0.926c0.139-0.111,0.287-0.23,0.467-0.35
                        c0.574-0.384,2.561-1.6,4.012-1.091c2.244,0.788,4.582,0.969,6.412-3.02c1.875-4.077-2.934-8.051-3.141-8.219l-1.262,1.553
                        c0.039,0.03,3.799,3.189,2.586,5.83c-1.324,2.882-2.475,2.478-3.936,1.969c-2.166-0.762-4.555,0.497-5.779,1.313
                        c-0.23,0.153-0.424,0.306-0.604,0.449c-0.45,0.357-0.618,0.491-1.247,0.491s-0.798-0.134-1.248-0.493
                        c-0.18-0.143-0.371-0.294-0.6-0.446c-1.227-0.817-3.607-2.078-5.781-1.313c-1.458,0.51-2.611,0.913-3.936-1.969
                        c-1.227-2.669,2.55-5.8,2.588-5.831l-1.263-1.552c-0.206,0.168-5.016,4.142-3.143,8.219
                        C98.753,129.477,101.086,129.298,103.334,128.51z"/>
                </g>
            </svg>
        )
    }
}
